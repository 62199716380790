import React, { useState, useEffect, useContext } from "react";
import mediaQuery from "../mediaQueries";
import "../Components/prince.css";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AiOutlineRollback } from "react-icons/ai";
import { DataContext } from "../context/DataContext";

function Prince() {
  const location = useLocation();
  const result = useContext(DataContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
  const [isDropdownOpen4, setIsDropdownOpen4] = useState(false);
  const [isDropdownOpen5, setIsDropdownOpen5] = useState(false);
  const [isDropdownOpen7, setIsDropdownOpen7] = useState(false);
  const [isDropdownOpen6, setIsDropdownOpen6] = useState(false);
  const [isDropdownOpen10, setIsDropdownOpen10] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [employeeData, setEmployee] = useState({});
  const navigation = useNavigate();

  const apiUrl = process.env.REACT_APP_URL;
  // apiUrl;
  // const navigate = useNavigate();
  const navigate = useNavigate();
  const Token = localStorage.getItem("Token");

  const shouldShowButton = () => {
    // Check if the current path is not the dashboard path
    return location.pathname !== "/Dashboard";
  };
  useEffect(() => {
    setEmployee(result.employeeData);
  });

  const goBack = () => {
    navigation(-1);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setIsDropdownOpen1(false);
    setIsDropdownOpen2(false);
    setIsDropdownOpen3(false);
    setIsDropdownOpen4(false);
    setIsDropdownOpen5(false);
    setIsDropdownOpen6(false);
    setIsDropdownOpen7(false);
  };

  document.addEventListener("click", () => {
    setIsDropdownOpen(false);
    setIsDropdownOpen1(false);
    setIsDropdownOpen2(false);
    setIsDropdownOpen3(false);
    setIsDropdownOpen4(false);
    setIsDropdownOpen5(false);
    setIsDropdownOpen6(false);
    setIsDropdownOpen7(false);
  });

  const toggleDropdown1 = () => {
    setIsDropdownOpen1(!isDropdownOpen1);
    setIsDropdownOpen(false);
    setIsDropdownOpen2(false);
    setIsDropdownOpen3(false);
    setIsDropdownOpen4(false);
    setIsDropdownOpen5(false);
    setIsDropdownOpen6(false);
    setIsDropdownOpen7(false);
  };

  const toggleDropdown2 = () => {
    setIsDropdownOpen2(!isDropdownOpen2);
    setIsDropdownOpen1(false);
    setIsDropdownOpen3(false);
    setIsDropdownOpen4(false);
    setIsDropdownOpen(false);
    setIsDropdownOpen5(false);
    setIsDropdownOpen6(false);
    setIsDropdownOpen7(false);
  };

  const toggleDropdown3 = () => {
    setIsDropdownOpen3(!isDropdownOpen3);
    setIsDropdownOpen1(false); // Close Dropdown 1
    setIsDropdownOpen2(false);
    setIsDropdownOpen4(false);
    setIsDropdownOpen(false);
    setIsDropdownOpen5(false);
    setIsDropdownOpen6(false);
    setIsDropdownOpen7(false);
  };

  const toggleDropdown4 = () => {
    setIsDropdownOpen4(!isDropdownOpen4);
    setIsDropdownOpen1(false); // Close Dropdown 1
    setIsDropdownOpen2(false);
    setIsDropdownOpen3(false);
    setIsDropdownOpen5(false);
    setIsDropdownOpen(false);
    setIsDropdownOpen6(false);
    setIsDropdownOpen7(false);
  };

  const toggleDropdown5 = () => {
    setIsDropdownOpen5(!isDropdownOpen5);
    setIsDropdownOpen1(false); // Close Dropdown 1
    setIsDropdownOpen2(false);
    setIsDropdownOpen3(false);
    setIsDropdownOpen4(false);
    setIsDropdownOpen(false);
    setIsDropdownOpen6(false);
    setIsDropdownOpen7(false);
  };

  const toggleDropdown6 = () => {
    setIsDropdownOpen6(!isDropdownOpen6);
    setIsDropdownOpen1(false); // Close Dropdown 1
    setIsDropdownOpen2(false);
    setIsDropdownOpen3(false);
    setIsDropdownOpen4(false);
    setIsDropdownOpen5(false);
    setIsDropdownOpen7(false);
    setIsDropdownOpen(false);
  };
  const toggleDropdown7 = () => {
    setIsDropdownOpen7(!isDropdownOpen7);
    setIsDropdownOpen1(false); // Close Dropdown 1
    setIsDropdownOpen2(false);
    setIsDropdownOpen3(false);
    setIsDropdownOpen4(false);
    setIsDropdownOpen5(false);
    setIsDropdownOpen(false);
    setIsDropdownOpen6(false);
  };

  const handleInprogressClick = (pending) => {
    navigate("/cases/In Progress", { state: { pending } });
  };

  const linkStyle = {
    color: "white",
  };
  const linkHoverStyle = {
    color: isHovered ? "white" : "#808080", // Text color changes on hover
    transition: "color 0.3s",
  };
  const navBarStyle = {
    backgroundColor: "#3e4870",
    display: "flex",
    //    position:'fixed',
    flexDirection: "row",
    alignItems: "center",
  };
  const openDropdownItemStyle = {
    color: "black",
  };

  const responsiveDropdown = {
    [mediaQuery.small]: {
      display: "none",
    },
  };

  const responsiveNavItem = {
    [mediaQuery.medium]: {},
    [mediaQuery.small]: {
      width: "50%",
      textAlign: "left",
      padding: "10px 0",
    },
  };

  const handleNewClick = (stage) => {
    navigation("/leads/Open", { state: { stage, loaderState: true } });
  };
  return (
    <div style={{ ...navBarStyle, paddingTop: "8px" }} className="text-center">
      <div
        className="nav-item dropdown"
        style={{ ...responsiveNavItem, ...responsiveDropdown }}
      >
        <Link className={`nav-link`} style={linkStyle} to="/Dashboard">
          <i className="fas fa-home me-2"></i>
        </Link>
      </div>

      {employeeData.userType === "1" && (
        <div
          className="nav-item dropdown"
          style={{ ...responsiveNavItem, ...responsiveDropdown }}
        >
          <a
            className={`nav-link dropdown-toggle ${
              isDropdownOpen ? "show" : ""
            }`}
            id="navbarDropdown"
            role="button"
            onClick={(e) => {
              e.stopPropagation();
              toggleDropdown();
            }}
            aria-haspopup="true"
            aria-expanded={isDropdownOpen}
            style={linkStyle}
          >
            <i className="fas fa-wallet me-2"></i>
            SETUP
          </a>
          <div
            className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}
            aria-labelledby="navbarDropdown"
            style={isDropdownOpen ? { borderRadius: "10px" } : {}}
          >
            <Link
              to="/Master"
              className="dropdown-item"
              href="#"
              style={isDropdownOpen ? openDropdownItemStyle : linkHoverStyle}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <i className="angle fe fe-chevron-right"></i>
              Master
            </Link>

            <Link
              to="/MasterVal"
              className="dropdown-item"
              href="#"
              style={isDropdownOpen ? openDropdownItemStyle : linkHoverStyle}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <i className="angle fe fe-chevron-right"></i>
              Master Value
            </Link>
            <Link
              to="/case-type"
              className="dropdown-item"
              href="#"
              style={isDropdownOpen ? openDropdownItemStyle : linkHoverStyle}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <i className="angle fe fe-chevron-right"></i>
              Case Type
            </Link>
            <Link
              to="/case-subtype"
              className="dropdown-item"
              href="#"
              style={isDropdownOpen ? openDropdownItemStyle : linkHoverStyle}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <i className="angle fe fe-chevron-right"></i>
              Case Subtype
            </Link>
          </div>
        </div>
      )}

      <a
        className=""
        id="navbarDropdown1"
        role="button"
        onClick={(e) => {
          e.stopPropagation();
          toggleDropdown1();
        }}
        aria-haspopup="true"
        aria-expanded={isDropdownOpen1}
        style={linkStyle}
      ></a>

      {employeeData.userType === "1" && (
        <div
          className="nav-item dropdown"
          style={{ ...responsiveNavItem, ...responsiveDropdown }}
        >
          <a
            className={`nav-link dropdown-toggle ${
              isDropdownOpen1 ? "show" : ""
            }`}
            id="navbarDropdown1"
            role="button"
            onClick={(e) => {
              e.stopPropagation();
              toggleDropdown1();
            }}
            aria-haspopup="true"
            aria-expanded={isDropdownOpen1}
            style={linkStyle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <i className="fas fa-wallet me-2"></i>
            HRMS
          </a>
          <div
            className={`dropdown-menu ${isDropdownOpen1 ? "show" : ""}`}
            aria-labelledby="navbarDropdown1"
            style={isDropdownOpen1 ? { borderRadius: "10px" } : {}}
          >
            <Link
              to="/MainPage2"
              className="dropdown-item"
              style={isDropdownOpen1 ? openDropdownItemStyle : linkHoverStyle}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <i className="angle fe fe-chevron-right"></i>
              Manage Employee
            </Link>
          </div>
        </div>
      )}

      {/* Dropdown 3 */}
      <a
        className=""
        id="navbarDropdown3"
        role="button"
        onClick={(e) => {
          e.stopPropagation();
          toggleDropdown3();
        }}
        aria-haspopup="true"
        aria-expanded={isDropdownOpen3}
        style={linkStyle}
      ></a>

      <div
        className="nav-item dropdown"
        style={{
          marginRight: "10px",
          ...responsiveNavItem,
          ...responsiveDropdown,
        }}
      >
        <a
          className={`nav-link dropdown-toggle ${
            isDropdownOpen3 ? "show" : ""
          }`}
          href="#"
          id="navbarDropdown3"
          role="button"
          onClick={(e) => {
            e.stopPropagation();
            toggleDropdown3();
          }}
          aria-haspopup="true"
          aria-expanded={isDropdownOpen3}
          style={linkStyle}
        >
          <i className="fas fa-wallet me-2"></i>
          Plans
        </a>
        <div
          className={`dropdown-menu ${isDropdownOpen3 ? "show" : ""}`}
          aria-labelledby="navbarDropdown3"
          style={isDropdownOpen3 ? { borderRadius: "10px" } : {}}
        >
          <Link
            to="/plans"
            className="dropdown-item"
            href="#"
            style={isDropdownOpen3 ? openDropdownItemStyle : linkHoverStyle}
          >
            <i className="angle fe fe-chevron-right"></i>
            Plans
          </Link>
          <Link
            to="/custom-plans"
            className="dropdown-item"
            href="#"
            style={isDropdownOpen3 ? openDropdownItemStyle : linkHoverStyle}
          >
            <i className="angle fe fe-chevron-right"></i>
            Custom Plans
          </Link>
        </div>
      </div>

      {/* Dropdown 4 */}
      <a
        className=""
        id="navbarDropdown4"
        role="button"
        onClick={(e) => {
          e.stopPropagation();
          toggleDropdown4();
        }}
        aria-haspopup="true"
        aria-expanded={isDropdownOpen4}
        style={linkStyle}
      ></a>

      <div
        className="nav-item dropdown"
        style={{
          marginRight: "10px",
          ...responsiveNavItem,
          ...responsiveDropdown,
        }}
      >
        <a
          className={`nav-link dropdown-toggle ${
            isDropdownOpen4 ? "show" : ""
          }`}
          id="navbarDropdown4"
          role="button"
          onClick={(e) => {
            e.stopPropagation();
            toggleDropdown4();
          }}
          aria-haspopup="true"
          aria-expanded={isDropdownOpen4}
          style={linkStyle}
        >
          <i className="fas fa-wallet me-2"></i>
          Partners
        </a>
        <div
          className={`dropdown-menu ${isDropdownOpen4 ? "show" : ""}`}
          aria-labelledby="navbarDropdown4"
          style={isDropdownOpen4 ? { borderRadius: "10px" } : {}}
        >
          <Link
            to="/partners"
            className="dropdown-item"
            href="#"
            style={isDropdownOpen4 ? openDropdownItemStyle : linkStyle}
          >
            <i className="angle fe fe-chevron-right"></i>
            Partners
          </Link>
        </div>
      </div>

      {/* Dropdown 2 */}
      {[
        "KAM",
        "Client Support",
        "Tech-Support",
        "Finance",
        "Logistics Operations",
        "Legal",
      ].includes(employeeData.userRole) && employeeData.userType == 0 ? null : (
        <>
          <a
            className=""
            id="navbarDropdown2"
            role="button"
            onClick={(e) => {
              e.stopPropagation();
              toggleDropdown2();
            }}
            aria-haspopup="true"
            aria-expanded={isDropdownOpen2}
            style={linkStyle}
          ></a>

          <div
            className="nav-item dropdown"
            style={{ ...responsiveNavItem, ...responsiveDropdown }}
          >
            <a
              className={`nav-link dropdown-toggle ${
                isDropdownOpen2 ? "show" : ""
              }`}
              id="navbarDropdown2"
              role="button"
              onClick={(e) => {
                e.stopPropagation();
                toggleDropdown2();
              }}
              aria-haspopup="true"
              aria-expanded={isDropdownOpen2}
              style={linkStyle}
            >
              <i className="fas fa-wallet me-2"></i>
              Sales CRM
            </a>
            <div
              className={`dropdown-menu ${isDropdownOpen2 ? "show" : ""}`}
              aria-labelledby="navbarDropdown2"
              style={isDropdownOpen2 ? { borderRadius: "10px" } : {}}
              onClick={() => {
                setIsDropdownOpen2(!isDropdownOpen2);
              }}
            >
              <button
                // to="/leads/Open"
                className="dropdown-item"
                // href="#"

                style={isDropdownOpen2 ? openDropdownItemStyle : linkStyle}
                onClick={() => handleNewClick("new")}
              >
                <i className="angle fe fe-chevron-right"></i>
                New Leads
              </button>
              {/* <Link className="" to="/Reports" style={isDropdownOpen2 ? openDropdownItemStyle : linkStyle}><i class="angle fe fe-chevron-right"></i>

                        Reports
                    </Link> */}
              <button
                // to="/NewLeads/call-attempted"
                className="dropdown-item"
                // href="#"
                style={isDropdownOpen2 ? openDropdownItemStyle : linkStyle}
                onClick={() => handleNewClick("call-attempted")}
              >
                <i className="angle fe fe-chevron-right"></i>
                Call Attempted
              </button>
              <button
                // to="/NewLeads/contact-in-future"
                className="dropdown-item"
                // href="#"
                style={isDropdownOpen2 ? openDropdownItemStyle : linkStyle}
                onClick={() => handleNewClick("contact-in-future")}
              >
                <i className="angle fe fe-chevron-right"></i>
                Contact In Future
              </button>
              <button
                // to="/NewLeads/follow-up"
                className="dropdown-item"
                // href="#"
                style={isDropdownOpen2 ? openDropdownItemStyle : linkStyle}
                onClick={() => handleNewClick("follow-up")}
              >
                <i className="angle fe fe-chevron-right"></i>
                Follow Up
              </button>
              <button
                // to="/NewLeads/proposal"
                className="dropdown-item"
                // href="#"
                style={isDropdownOpen2 ? openDropdownItemStyle : linkStyle}
                onClick={() => handleNewClick("proposal")}
              >
                <i className="angle fe fe-chevron-right"></i>
                Proposal
              </button>
              <button
                // to="/NewLeads/negotiation"
                className="dropdown-item"
                // href="#"
                style={isDropdownOpen2 ? openDropdownItemStyle : linkStyle}
                onClick={() => handleNewClick("negotiation")}
              >
                <i className="angle fe fe-chevron-right"></i>
                Negotiation
              </button>
              <button
                // to="/NewLeads/integration-inprogress"
                className="dropdown-item"
                // href="#"
                style={isDropdownOpen2 ? openDropdownItemStyle : linkStyle}
                onClick={() => handleNewClick("integration-inprogress")}
              >
                <i className="angle fe fe-chevron-right"></i>
                In-Integration
              </button>
              <button
                // to="/NewLeads/closed-won-SYTS"
                className="dropdown-item"
                // href="#"
                style={isDropdownOpen2 ? openDropdownItemStyle : linkStyle}
                onClick={() => handleNewClick("closed-won-SYTS")}
              >
                <i className="angle fe fe-chevron-right"></i>
                Closed WON-SYTS
              </button>
              <button
                // to="/NewLeads/lost"
                className="dropdown-item"
                // href="#"
                style={isDropdownOpen2 ? openDropdownItemStyle : linkStyle}
                onClick={() => handleNewClick("lost")}
              >
                <i className="angle fe fe-chevron-right"></i>
                Lost Leads
              </button>
            </div>
          </div>
        </>
      )}

      {/* Dropdown 5 */}
      {employeeData.userRole == "Agent" ? null : (
        <>
          {" "}
          <a
            className=""
            id="navbarDropdown5"
            role="button"
            onClick={(e) => {
              e.stopPropagation();
              toggleDropdown5();
            }}
            aria-haspopup="true"
            aria-expanded={isDropdownOpen5}
            style={linkStyle}
          ></a>
          <div
            className="nav-item dropdown"
            style={{
              marginRight: "10px",
              ...responsiveNavItem,
              ...responsiveDropdown,
            }}
          >
            <a
              className={`nav-link dropdown-toggle ${
                isDropdownOpen5 ? "show" : ""
              }`}
              id="navbarDropdown5"
              role="button"
              onClick={(e) => {
                e.stopPropagation();
                toggleDropdown5();
              }}
              aria-haspopup="true"
              aria-expanded={isDropdownOpen5}
              style={linkStyle}
            >
              <i className="fas fa-wallet me-2"></i>
              Customers
            </a>
            <div
              className={`dropdown-menu ${isDropdownOpen5 ? "show" : ""}`}
              aria-labelledby="navbarDropdown5"
              style={isDropdownOpen5 ? { borderRadius: "10px" } : {}}
            >
              <Link
                to="/customers"
                className="dropdown-item"
                href="#"
                style={isDropdownOpen5 ? openDropdownItemStyle : linkStyle}
              >
                <i className="angle fe fe-chevron-right"></i>
                Customers
              </Link>
              <Link
                to="/all-report"
                className="dropdown-item"
                href="#"
                style={isDropdownOpen5 ? openDropdownItemStyle : linkStyle}
              >
                <i className="angle fe fe-chevron-right"></i>
                Customers Order Report
              </Link>
              {/* <Link
                to="/closure-report"
                className="dropdown-item"
                href="#"
                style={isDropdownOpen5 ? openDropdownItemStyle : linkStyle}
              >
                <i className="angle fe fe-chevron-right"></i>
                Closure Report
              </Link>
              <Link
                to="/pipeline-report"
                className="dropdown-item"
                href="#"
                style={isDropdownOpen5 ? openDropdownItemStyle : linkStyle}
              >
                <i className="angle fe fe-chevron-right"></i>
                Pipeline Report
              </Link>
              <Link
                to="/-report"
                className="dropdown-item"
                href="#"
                style={isDropdownOpen5 ? openDropdownItemStyle : linkStyle}
              >
                <i className="angle fe fe-chevron-right"></i>
                Lead Status Report
              </Link> */}
            </div>
          </div>
        </>
      )}

      {/* Dropdown 6  */}
      {employeeData.userRole == "Agent" ? null : (
        <>
          <a
            className=""
            id="navbarDropdown6"
            role="button"
            onClick={(e) => {
              e.stopPropagation();
              toggleDropdown6();
            }}
            aria-haspopup="true"
            aria-expanded={isDropdownOpen6}
            style={linkStyle}
          ></a>
          <div
            className="nav-item dropdown"
            style={{
              marginRight: "10px",
              ...responsiveNavItem,
              ...responsiveDropdown,
            }}
          >
            <a
              className={`nav-link dropdown-toggle ${
                isDropdownOpen6 ? "show" : ""
              }`}
              id="navbarDropdown6"
              role="button"
              onClick={(e) => {
                e.stopPropagation();
                toggleDropdown6();
              }}
              aria-haspopup="true"
              aria-expanded={isDropdownOpen6}
              style={linkStyle}
            >
              <i className="fas fa-wallet me-2"></i>
              Cases
            </a>
            <div
              className={`dropdown-menu ${isDropdownOpen6 ? "show" : ""}`}
              aria-labelledby="navbarDropdown6"
              style={isDropdownOpen6 ? { borderRadius: "10px" } : {}}
            >
              <Link
                to="/cases/All"
                className="dropdown-item"
                href="#"
                style={isDropdownOpen6 ? openDropdownItemStyle : linkStyle}
              >
                <i className="angle fe fe-chevron-right"></i>
                All Case
              </Link>
              <Link
                to="/cases/New"
                className="dropdown-item"
                href="#"
                style={isDropdownOpen6 ? openDropdownItemStyle : linkStyle}
              >
                <i className="angle fe fe-chevron-right"></i>
                New
              </Link>
              <button
                // to="/cases/In Progress"
                onClick={() => handleInprogressClick("In Progress")}
                className="dropdown-item"
                href="#"
                style={isDropdownOpen6 ? openDropdownItemStyle : linkStyle}
              >
                <i className="angle fe fe-chevron-right"></i>
                In Progress
              </button>
              <Link
                to="/cases/Closed"
                className="dropdown-item"
                href="#"
                style={isDropdownOpen6 ? openDropdownItemStyle : linkStyle}
              >
                <i className="angle fe fe-chevron-right"></i>
                Closed
              </Link>
            </div>
          </div>
        </>
      )}
      <div
        className="nav-item dropdown"
        style={{
          marginRight: "10px",
          ...responsiveNavItem,
          ...responsiveDropdown,
        }}
      >
        <a
          className={`nav-link dropdown-toggle ${
            isDropdownOpen7 ? "show" : ""
          }`}
          id="navbarDropdown5"
          role="button"
          onClick={(e) => {
            e.stopPropagation();
            toggleDropdown7();
          }}
          aria-haspopup="true"
          aria-expanded={isDropdownOpen7}
          style={linkStyle}
        >
          <i className="fas fa-wallet me-2"></i>
          Reports
        </a>
        <div
          className={`dropdown-menu ${isDropdownOpen7 ? "show" : ""}`}
          aria-labelledby="navbarDropdown5"
          style={isDropdownOpen7 ? { borderRadius: "10px" } : {}}
        >
          {/* <Link
            to="/customers"
            className="dropdown-item"
            href="#"
            style={isDropdownOpen5 ? openDropdownItemStyle : linkStyle}
          >
            <i className="angle fe fe-chevron-right"></i>
            Customers
          </Link> */}
          {/* <Link
            to="/all-report"
            className="dropdown-item"
            href="#"
            style={isDropdownOpen7 ? openDropdownItemStyle : linkStyle}
          >
            <i className="angle fe fe-chevron-right"></i>
            Customers Order Report
          </Link> */}
          <Link
            to="/closure-report"
            className="dropdown-item"
            href="#"
            style={isDropdownOpen7 ? openDropdownItemStyle : linkStyle}
          >
            <i className="angle fe fe-chevron-right"></i>
            Closure Report
          </Link>
          <Link
            to="/pipeline-report"
            className="dropdown-item"
            href="#"
            style={isDropdownOpen7 ? openDropdownItemStyle : linkStyle}
          >
            <i className="angle fe fe-chevron-right"></i>
            Pipeline Report
          </Link>
          <Link
            to="/lead-status-report"
            className="dropdown-item"
            href="#"
            style={isDropdownOpen7 ? openDropdownItemStyle : linkStyle}
          >
            <i className="angle fe fe-chevron-right"></i>
            Lead Status Report
          </Link>
          <Link
            to="/activity-report"
            className="dropdown-item"
            href="#"
            style={isDropdownOpen7 ? openDropdownItemStyle : linkStyle}
          >
            <i className="angle fe fe-chevron-right"></i>
            Activity Report
          </Link>
          <Link
            to="/support-ticket-report"
            className="dropdown-item"
            href="#"
            style={isDropdownOpen7 ? openDropdownItemStyle : linkStyle}
          >
            <i className="angle fe fe-chevron-right"></i>
            Support Ticket Report
          </Link>
          <Link
            to="/client-response-report"
            className="dropdown-item"
            href="#"
            style={isDropdownOpen7 ? openDropdownItemStyle : linkStyle}
          >
            <i className="angle fe fe-chevron-right"></i>
            Client Response Report
          </Link>
        </div>
      </div>

      {shouldShowButton() && (
        <div
          style={{
            position: "absolute",
            right: "30px",
            top: "69px",
          }}
        >
          <button
            onClick={goBack}
            style={{
              display: "inline",
              backgroundColor: "rgb(62, 72, 112)",
              border: "none",
              color: "#fff",
            }}
          >
            Go Back{" "}
            <AiOutlineRollback size={20} style={{ display: "inline" }} />
          </button>
        </div>
      )}
    </div>
  );
}

export default Prince;
