import { useState } from "react";
import React from "react";
import Loader from "../../assets/img/loader.gif";

let num = 0;

function TabComponent({
  handleStatusChange,
  linkActive,
  countCallAttempted,
  countFollowup,
  countLOST,
  countNew,
  countOpen,
  countWon,
  countconatactInFuture,
  countintergrationInPro,
  countnegotiation,
  countproposal,
  status,
}) {
  console.log("DEEP", num++);
  const [loader, setLoader] = useState(false);

  const handleLoader = () => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };
  return (
    <>
      {status === "Open" && (
        <ul className="nav panel-tabs d-flex">
          <li className="">
            <a
              role="button"
              onClick={() => {
                handleStatusChange("new");
                handleLoader();
              }}
              className={linkActive === "new" ? "active" : ""}
            >
              New ({countNew})
            </a>
          </li>
          <li>
            <a
              role="button"
              onClick={() => {
                handleStatusChange("call-attempted");
                handleLoader();
              }}
              className={linkActive === "call-attempted" ? "active" : ""}
            >
              Call Attempted ({countCallAttempted})
            </a>
          </li>
          <li>
            <a
              role="button"
              onClick={() => {
                handleStatusChange("contact-in-future");
                handleLoader();
              }}
              className={linkActive === "contact-in-future" ? "active" : ""}
            >
              Contact in future ({countconatactInFuture})
            </a>
          </li>
          <li>
            <a
              role="button"
              onClick={() => {
                handleStatusChange("follow-up");
                handleLoader();
              }}
              className={linkActive === "follow-up" ? "active" : ""}
            >
              Follow-up ({countFollowup})
            </a>
          </li>
          <li>
            <a
              role="button"
              onClick={() => {
                handleStatusChange("proposal");
                handleLoader();
              }}
              className={linkActive === "proposal" ? "active" : ""}
            >
              Proposal ({countproposal})
            </a>
          </li>
          <li>
            <a
              role="button"
              onClick={() => {
                handleStatusChange("negotiation");
                handleLoader();
              }}
              className={linkActive === "negotiation" ? "active" : ""}
            >
              Negotiation ({countnegotiation})
            </a>
          </li>
          <li>
            <a
              role="button"
              onClick={() => {
                handleStatusChange("integration-inprogress");
                handleLoader();
              }}
              className={
                linkActive === "integration-inprogress" ? "active" : ""
              }
            >
              In-Integration ({countintergrationInPro})
            </a>
          </li>
          <li>
            <a
              role="button"
              onClick={() => {
                handleStatusChange("closed-won-SYTS");
                handleLoader();
              }}
              className={linkActive === "closed-won-SYTS" ? "active" : ""}
            >
              Closed Won- SYTS ({countWon})
            </a>
          </li>
          <li>
            <a
              role="button"
              onClick={() => {
                handleStatusChange("lost");
                handleLoader();
              }}
              className={linkActive === "lost" ? "active" : ""}
            >
              Lost ({countLOST})
            </a>
          </li>
          <li>
            <a
              role="button"
              onClick={() => {
                handleStatusChange("Open");
                handleLoader();
              }}
              className={linkActive === "Open" ? "active" : ""}
            >
              All ({countOpen})
            </a>
          </li>
        </ul>
      )}
      {loader ? (
        <>
          {" "}
          <div className="loading-overlay">
            <img src={Loader} alt="Loading..." />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default TabComponent;
