import React from "react";

import { FaShoppingCart } from "react-icons/fa"; // Example Icon (can be any from react-icons)

const CardComponent = ({ title, count, link }) => {
  return (
    <div className="card-container">
      <div>
        <div className="card-body">
          <div className="d-flex align-items-center">
            <div className="flex-grow-1 overflow-hidden">
              <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                {title}
              </p>
            </div>
            <div className="flex-shrink-0">
              <h5 className="text-success fs-14 mb-0">
                <i className="ri-arrow-right-up-line fs-13 align-middle"></i>{" "}
                +16.24 %
              </h5>
            </div>
          </div>
          <div className="d-flex align-items-end justify-content-between mt-3">
            <div>
              <h4 className="fs-22 fw-semibold ff-secondary mb-3">
                <span className="counter-value" data-target="559.25">
                  {count}
                </span>
                {/* <span className="text-success fs-14 mb-0">
                  <i className="ri-arrow-right-up-line fs-13 align-middle"></i>{" "}
                  lead
                </span> */}
              </h4>
              <a href="" className="text-decoration-underline">
                {link}
              </a>
            </div>
            <div>
              <span
                className="avatar-title rounded fs-2 p-2"
                style={{ backgroundColor: "#FFF4B5" }}
              >
                <FaShoppingCart color="#D91656" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardComponent;
