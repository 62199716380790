import React from "react";
import { useNavigate } from "react-router-dom";
import "./index2.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";

const boxStyle = {
  width: "400px", //
  height: "25px",
  padding: "2px", // Optional padding
  border: "1px solid #ccc", // Optional border
  overflow: "auto", // Enables scroll if content exceeds the box
  boxSizing: "border-box", // Ensures padding is included in the width and height
  backgroundColor: "#fff", // Optional background color
  borderRadius: "2px",
};
function TableComponent({
  bulkAssignId,
  setBulkAssignId,
  status,
  filteredLeads,
  setCaseId,
  newStatus,
  setNewStatus,
  leadStatusOptions,
  updateStatus,
  totalPages,
  currentPage,
  handlePageChange,
  handlePageSize,
  handleCloseStatusModal,
  setModalOpenAwb,
  setModalOpenOrderId,
  setModalOpenAssign,
  setAssignedEmployeeId,
  setModalOpenStatusChange,
  statusModalOPen, // Corrected variable name
}) {
  const navigate = useNavigate();
  const isNumber = (status) => !isNaN(status);
  return (
    <>
      {" "}
      <>
        <div className="table-responsive p-4">
          <table className="table table-striped table-bordered text-nowrap mb-0">
            <thead>
              <tr>
                {
                  <th>
                    <input
                      type="checkbox"
                      id="bulkSelect"
                      onChange={(e) => {
                        let newArr = [];
                        const allLeadsCheck =
                          document.getElementsByClassName("selectLead");
                        if (e.target.checked) {
                          for (let i = 0; i < filteredLeads.length; i++) {
                            newArr.push(filteredLeads[i].id);
                          }
                          setBulkAssignId(newArr);
                          for (let i = 0; i < allLeadsCheck.length; i++) {
                            allLeadsCheck[i].checked = true;
                          }
                        } else {
                          newArr = [];
                          setBulkAssignId([]);
                          for (let i = 0; i < allLeadsCheck.length; i++) {
                            allLeadsCheck[i].checked = false;
                          }
                        }
                        // console.log(newArr, "======>>>>changed");
                      }}
                    />
                  </th>
                }
                <th>ID</th>
                <th>CASE CATEGORY</th>
                <th>CASE SUBCATEGORY</th>
                <th>CLIENT DETAILS</th>
                <th>CASE DETAILS</th>
                <th>ASSIGNED TO</th>
                <th>ACTIONS</th>
                {/* <th>ASSIGNED TO</th> */}
              </tr>
            </thead>
            <tbody>
              {isNumber(status) == true
                ? filteredLeads?.map((user) => (
                    <tr key={user.id}>
                      {
                        <td className="p-4">
                          <input
                            type="checkbox"
                            className="selectLead"
                            defaultValue="Initial Value"
                            onChange={(e) => {
                              let newArray = [...bulkAssignId];
                              if (e.target.checked) {
                                newArray.push(user.id);
                                setBulkAssignId(newArray);
                              } else {
                                newArray = [...bulkAssignId];
                                newArray = newArray.filter((data) => {
                                  return data !== user.id;
                                });
                                setBulkAssignId(newArray);
                              }
                            }}
                          />
                        </td>
                      }
                      <td>
                        <p style={{ marginBottom: "5px" }}>
                          ID-<b>{user.id}</b>
                        </p>
                      </td>
                      <td>
                        <p className="mb-0">
                          <>{user.caseTypeName}</>
                        </p>
                      </td>
                      <td>
                        <p className="mb-0">
                          <>{user.caseSubTypeName}</>
                        </p>
                      </td>

                      <td>
                        {
                          <p className="mb-0">
                            <b>
                              {user.customer.primaryContactName
                                ?.charAt(0)
                                .toUpperCase() +
                                user.customer.primaryContactName?.slice(1)}
                            </b>
                            <br />
                            Email: <b>{user.customer.emailId}</b>
                            <br />
                            Phone: <b>{user.customer.mobileNumber}</b>
                          </p>
                        }
                      </td>

                      <td>
                        {user.awbNo.length === 1 ? (
                          <p className="mb-0">
                            Awb No: <b>{user.awbNo[0]?.awbNo}</b>
                          </p>
                        ) : user.awbNo.length === 0 ? null : (
                          <p className="mb-0">
                            Awb No:{" "}
                            <b
                              style={{
                                cursor: "pointer",
                                color: "#032852",
                              }}
                              onClick={() => {
                                setCaseId(user.id);
                                // handleAwbNoList();
                                setModalOpenAwb(true);
                              }}
                            >
                              Click Here
                            </b>
                          </p>
                        )}

                        {user.orderId.length === 1 ? (
                          <p className="mb-0">
                            Order Id: <b>{user.orderId[0]?.orderId}</b>
                          </p>
                        ) : user.orderId.length === 0 ? null : (
                          <p className="mb-0">
                            Order Id:{" "}
                            <b
                              style={{
                                cursor: "pointer",
                                color: "#032852",
                              }}
                              onClick={() => {
                                setCaseId(user.id);
                                // handleAwbNoList();
                                setModalOpenOrderId(true);
                              }}
                            >
                              Click Here
                            </b>
                          </p>
                        )}

                        {user.selectReason ? (
                          <p className="mb-0">
                            Select Reason : <b>{user.selectReason}</b>
                          </p>
                        ) : null}
                        {user.beneficiaryName ? (
                          <p className="mb-0">
                            Beneficiary Account Name :{" "}
                            <b>{user.beneficiaryName}</b>
                          </p>
                        ) : null}
                        {user.beneficiaryAccountNumber ? (
                          <p className="mb-0">
                            Beneficiary Account Number :{" "}
                            <b>{user.beneficiaryAccountNumber}</b>
                          </p>
                        ) : null}
                        {user.beneficiaryIfscCode ? (
                          <p className="mb-0">
                            Beneficiary IFSC Code :{" "}
                            <b>{user.beneficiaryIfscCode}</b>
                          </p>
                        ) : null}
                        {user.uploadImage ? (
                          <p className="mb-0">
                            Upload Image:{" "}
                            <b>
                              <a href={user.uploadImage} target="_blank">
                                Click Here
                              </a>
                            </b>
                          </p>
                        ) : null}
                        {user.otherIssueDescribe ? (
                          <div className="mb-0">
                            Other Issue Describe :{" "}
                            <p style={boxStyle}>
                              {" "}
                              <b>{user.otherIssueDescribe}</b>
                            </p>
                          </div>
                        ) : null}
                        {user.otherIssue ? (
                          <div className="mb-0">
                            Other Issue Describe :{" "}
                            <p style={boxStyle}>
                              {" "}
                              <b>{user.otherIssue}</b>
                            </p>
                          </div>
                        ) : null}
                        {user.discriptionIssue ? (
                          <div className="mb-0">
                            {user.relatedIssue !== " API Integration " &&
                            user.caseSubTypeId === 20
                              ? "Channel Credentials"
                              : "Discription Issue :"}
                            <p style={boxStyle}>
                              <b>{user.discriptionIssue}</b>
                            </p>
                          </div>
                        ) : null}
                        {user.selectIssue ? (
                          <p className="mb-0">
                            Select Issue : <b>{user.selectIssue}</b>
                          </p>
                        ) : null}
                        {user.transactionID ? (
                          <p className="mb-0">
                            Transaction ID : <b>{user.transactionID}</b>
                          </p>
                        ) : null}
                        {user.companyType ? (
                          <p className="mb-0">
                            Company Type: <b>{user.companyType}</b>
                          </p>
                        ) : null}
                        {user.relatedIssue ? (
                          <p className="mb-0">
                            Related Issue : <b>{user.relatedIssue}</b>
                          </p>
                        ) : null}
                        {user.selectChannel ? (
                          <p className="mb-0">
                            Select Channel : <b>{user.selectChannel}</b>
                          </p>
                        ) : null}
                        {user.selectOrderType ? (
                          <p className="mb-0">
                            Select Order Type: <b>{user.selectOrderType}</b>
                          </p>
                        ) : null}
                        {user.timePeriod ? (
                          <p className="mb-0">
                            Time Period : <b>{user.timePeriod}</b>
                          </p>
                        ) : null}
                        {user.receivedIncorrectInvoice ? (
                          <p className="mb-0">
                            Received Incorrect Invoice :{" "}
                            <b>{user.receivedIncorrectInvoice}</b>
                          </p>
                        ) : null}
                        {user.incorrectMissingCompanyName ? (
                          <p className="mb-0">
                            Incorrect Missing Company Name :{" "}
                            <b>{user.incorrectMissingCompanyName}</b>
                          </p>
                        ) : null}
                        {user.incorrectMissingCompanyAddress ? (
                          <p className="mb-0">
                            Incorrect Missing Company Address :{" "}
                            <b>{user.incorrectMissingCompanyAddress}</b>
                          </p>
                        ) : null}
                        {user.incorrectMissingGSTIN ? (
                          <p className="mb-0">
                            Incorrect Missing GSTIN :{" "}
                            <b>{user.incorrectMissingGSTIN}</b>
                          </p>
                        ) : null}
                        {user.others ? (
                          <p className="mb-0">
                            Other : <b>{user.others}</b>
                          </p>
                        ) : null}
                        {user.invoiceNumber ? (
                          <p className="mb-0">
                            Invoice Number : <b>{user.invoiceNumber}</b>
                          </p>
                        ) : null}
                        {user.issuePlatform ? (
                          <p className="mb-0">
                            Platform Issue : <b>{user.issuePlatform}</b>
                          </p>
                        ) : null}
                      </td>

                      <td>
                        {user.EmployeeCasesM.map((item, index) => (
                          <p key={index} className="mb-0">
                            {" "}
                            {item.employee.firstName +
                              " " +
                              item.employee.lastName}
                          </p>
                        ))}
                      </td>
                      <td>
                        <a
                          className="btn ripple btn-danger btn-xs w-100 mb-1 mt-1"
                          onClick={() => navigate(`/support/${user.id}`)}
                          style={{ color: "#fff" }}
                        >
                          Activity
                        </a>
                        <br />

                        <a // href=""
                          className="btn ripple btn-info btn-xs w-100 mb-1"
                          onClick={() => {
                            setModalOpenAssign(true);
                            setAssignedEmployeeId(user.id);
                          }}
                          style={{ color: "#fff" }}
                        >
                          Assign
                        </a>

                        <br />
                        <a // href=""
                          className="btn ripple btn-info btn-xs w-100 mb-1"
                          onClick={() => {
                            setModalOpenStatusChange(true);
                            // setAssignedEmployeeId(user.id);
                            setCaseId(user.id);
                          }}
                          style={{ color: "#fff" }}
                        >
                          Status
                        </a>
                      </td>
                      <td>
                        {" "}
                        <div
                          className={`modal ${statusModalOPen ? "show" : ""}`}
                          style={{
                            display: statusModalOPen ? "block" : "none",
                          }}
                          tabIndex="-1"
                          role="dialog"
                        >
                          <div
                            className="modal-dialog modal-dialog-centered modal-xl"
                            role="document"
                          >
                            <div className="modal-content rounded-3">
                              <div className="modal-header">
                                <h5 className="modal-title">
                                  Lead Stage : {user?.status.toUpperCase()}
                                </h5>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  onClick={handleCloseStatusModal}
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>

                              <div
                                className="modal-body"
                                style={{ width: "100%" }}
                              >
                                <div className="row row-sm-1">
                                  <div className="col-sm-4 form-group">
                                    <label className="form-label">
                                      Set Stage :{" "}
                                    </label>
                                    <select
                                      name="newStatus"
                                      value={newStatus}
                                      className="form-control form-select select2"
                                      onChange={(e) => {
                                        setNewStatus(e.target.value);
                                      }}
                                    >
                                      <option>Select</option>
                                      {leadStatusOptions.map((data, index) => {
                                        return (
                                          <option value={data.name} key={index}>
                                            {data.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  <div className="col-lg-12">
                                    <button
                                      className="btn ripple btn-primary"
                                      type="button"
                                      onClick={updateStatus}
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                : filteredLeads?.map((user) => (
                    <tr key={user.id}>
                      {
                        <td className="p-4">
                          <input
                            defaultValue="Initial Value"
                            type="checkbox"
                            className="selectLead"
                            onChange={(e) => {
                              let newArray = [...bulkAssignId];
                              if (e.target.checked) {
                                newArray.push(user.id);
                                setBulkAssignId(newArray);
                              } else {
                                newArray = [...bulkAssignId];
                                newArray = newArray.filter((data) => {
                                  return data !== user.id;
                                });
                                setBulkAssignId(newArray);
                              }
                            }}
                          />
                        </td>
                      }

                      <td>
                        <p style={{ marginBottom: "5px" }}>
                          ID-<b>{user.id}</b>
                        </p>
                      </td>
                      <td>
                        <p className="mb-0">
                          <>{user.caseTypeName}</>
                        </p>
                      </td>
                      <td>
                        <p className="mb-0">
                          <>{user.caseSubTypeName}</>
                        </p>
                      </td>

                      <td>
                        {
                          <p className="mb-0">
                            <b>
                              {user.customer.primaryContactName
                                ?.charAt(0)
                                .toUpperCase() +
                                user.customer.primaryContactName?.slice(1)}
                            </b>
                            <br />
                            Email: <b>{user.customer.emailId}</b>
                            <br />
                            Phone: <b>{user.customer.mobileNumber}</b>
                          </p>
                        }
                      </td>
                      <td>
                        {user.awbNo.length === 1 ? (
                          <p className="mb-0">
                            Awb No: <b>{user.awbNo[0]?.awbNo}</b>
                          </p>
                        ) : user.awbNo.length === 0 ? null : (
                          <p className="mb-0">
                            Awb No:{" "}
                            <b
                              style={{
                                cursor: "pointer",
                                color: "#032852",
                              }}
                              onClick={() => {
                                setCaseId(user.id);
                                // handleAwbNoList();
                                setModalOpenAwb(true);
                              }}
                            >
                              Click Here
                            </b>
                          </p>
                        )}
                        {user.orderId.length === 1 ? (
                          user.orderId[0].orderId === "" ? null : (
                            <p className="mb-0">
                              Order Id: <b>{user.orderId[0]?.orderId}</b>
                            </p>
                          )
                        ) : user.orderId.length === 0 ? null : (
                          <p className="mb-0">
                            Order Id:{" "}
                            <b
                              style={{
                                cursor: "pointer",
                                color: "#032852",
                              }}
                              onClick={() => {
                                setCaseId(user.id);
                                // handleAwbNoList();
                                setModalOpenOrderId(true);
                              }}
                            >
                              Click Here
                            </b>
                          </p>
                        )}

                        {user.selectReason ? (
                          <p className="mb-0">
                            Select Reason : <b>{user.selectReason}</b>
                          </p>
                        ) : null}
                        {user.beneficiaryName ? (
                          <p className="mb-0">
                            Beneficiary Account Name :{" "}
                            <b>{user.beneficiaryName}</b>
                          </p>
                        ) : null}
                        {user.beneficiaryAccountNumber ? (
                          <p className="mb-0">
                            Beneficiary Account Number :{" "}
                            <b>{user.beneficiaryAccountNumber}</b>
                          </p>
                        ) : null}
                        {user.beneficiaryIfscCode ? (
                          <p className="mb-0">
                            Beneficiary IFSC Code :{" "}
                            <b>{user.beneficiaryIfscCode}</b>
                          </p>
                        ) : null}
                        {user.uploadImage ? (
                          <p className="mb-0">
                            Upload Image:{" "}
                            <b>
                              <a href={user.uploadImage} target="_blank">
                                Click Here
                              </a>
                            </b>
                          </p>
                        ) : null}
                        {user.otherIssueDescribe ? (
                          <div className="mb-0">
                            Other Issue Describe :{" "}
                            <p style={boxStyle}>
                              <b>{user.otherIssueDescribe}</b>
                            </p>
                          </div>
                        ) : null}
                        {user.otherIssue ? (
                          <div className="mb-0">
                            Other Issue Describe :{" "}
                            <p style={boxStyle}>
                              {" "}
                              <b>{user.otherIssue}</b>
                            </p>
                          </div>
                        ) : null}
                        {user.discriptionIssue ? (
                          <div className="mb-0">
                            {user.relatedIssue !== " API Integration " &&
                            user.caseSubTypeId === 20
                              ? "Channel Credentials"
                              : "Discription Issue :"}
                            <p style={boxStyle}>
                              {" "}
                              <b>{user.discriptionIssue}</b>
                            </p>
                          </div>
                        ) : null}
                        {user.selectIssue ? (
                          <p className="mb-0">
                            Select Issue : <b>{user.selectIssue}</b>
                          </p>
                        ) : null}
                        {user.transactionID ? (
                          <p className="mb-0">
                            Transaction ID : <b>{user.transactionID}</b>
                          </p>
                        ) : null}
                        {user.companyType ? (
                          <p className="mb-0">
                            Company Type: <b>{user.companyType}</b>
                          </p>
                        ) : null}
                        {user.relatedIssue ? (
                          <p className="mb-0">
                            Related Issue : <b>{user.relatedIssue}</b>
                          </p>
                        ) : null}
                        {user.selectChannel ? (
                          <p className="mb-0">
                            Select Channel : <b>{user.selectChannel}</b>
                          </p>
                        ) : null}
                        {user.selectOrderType ? (
                          <p className="mb-0">
                            Select Order Type: <b>{user.selectOrderType}</b>
                          </p>
                        ) : null}
                        {user.timePeriod ? (
                          <p className="mb-0">
                            Time Period : <b>{user.timePeriod}</b>
                          </p>
                        ) : null}
                        {user.receivedIncorrectInvoice ? (
                          <p className="mb-0">
                            Received Incorrect Invoice :{" "}
                            <b>{user.receivedIncorrectInvoice}</b>
                          </p>
                        ) : null}
                        {user.incorrectMissingCompanyName ? (
                          <p className="mb-0">
                            Incorrect Missing Company Name :{" "}
                            <b>{user.incorrectMissingCompanyName}</b>
                          </p>
                        ) : null}
                        {user.incorrectMissingCompanyAddress ? (
                          <p className="mb-0">
                            Incorrect Missing Company Address :{" "}
                            <b>{user.incorrectMissingCompanyAddress}</b>
                          </p>
                        ) : null}
                        {user.incorrectMissingGSTIN ? (
                          <p className="mb-0">
                            Incorrect Missing GSTIN :{" "}
                            <b>{user.incorrectMissingGSTIN}</b>
                          </p>
                        ) : null}
                        {user.others ? (
                          <p className="mb-0">
                            Other : <b>{user.others}</b>
                          </p>
                        ) : null}
                        {user.invoiceNumber ? (
                          <p className="mb-0">
                            Invoice Number : <b>{user.invoiceNumber}</b>
                          </p>
                        ) : null}
                        {user.issuePlatform ? (
                          <p className="mb-0">
                            Platform Issue : <b>{user.issuePlatform}</b>
                          </p>
                        ) : null}
                      </td>

                      <td>
                        {user.EmployeeCasesM.map((item, index) => (
                          <p key={index} className="mb-0">
                            {" "}
                            {item.employee.firstName +
                              " " +
                              item.employee.lastName}
                          </p>
                        ))}
                      </td>
                      <td>
                        <a
                          className="btn ripple btn-danger btn-xs w-100 mb-1 mt-1"
                          // onClick={() => loadcontent(user.id)}
                          onClick={() => navigate(`/support/${user.id}`)}
                          style={{ color: "#fff" }}
                        >
                          Activity
                        </a>
                        <br />

                        <a // href=""
                          className="btn ripple btn-info btn-xs w-100 mb-1"
                          onClick={() => {
                            setModalOpenAssign(true);
                            setAssignedEmployeeId(user.id);
                          }}
                          style={{ color: "#fff" }}
                        >
                          Assign
                        </a>
                        <br />
                        <a // href=""
                          className="btn ripple btn-info btn-xs w-100 mb-1"
                          onClick={() => {
                            setModalOpenStatusChange(true);
                            // setAssignedEmployeeId(user.id);
                            setCaseId(user.id);
                          }}
                          style={{ color: "#fff" }}
                        >
                          Status
                        </a>
                      </td>
                      <td>
                        <div
                          className={`modal ${statusModalOPen ? "show" : ""}`}
                          style={{
                            display: statusModalOPen ? "block" : "none",
                          }}
                          tabIndex="-1"
                          role="dialog"
                        >
                          <div
                            className="modal-dialog modal-dialog-centered modal-xl"
                            role="document"
                          >
                            <div className="modal-content rounded-3">
                              <div className="modal-header">
                                <h5 className="modal-title">
                                  Lead Stage : {user.status.toUpperCase()}
                                </h5>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  onClick={handleCloseStatusModal}
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>

                              <div
                                className="modal-body"
                                style={{ width: "100%" }}
                              >
                                <div className="row row-sm-1">
                                  <div className="col-sm-4 form-group">
                                    <label className="form-label">
                                      Set Stage :{" "}
                                    </label>
                                    <select
                                      name="newStatus"
                                      value={newStatus}
                                      className="form-control form-select select2"
                                      onChange={(e) => {
                                        setNewStatus(e.target.value);
                                      }}
                                    >
                                      <option>Select</option>
                                      {leadStatusOptions.map((data, index) => {
                                        return (
                                          <option value={data.name} key={index}>
                                            {data.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  <div className="col-lg-12">
                                    <button
                                      className="btn ripple btn-primary"
                                      type="button"
                                      onClick={updateStatus}
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
          {filteredLeads?.length === 0 ? (
            <div className="text-center row m-5">
              <h1>No Case Found</h1>
            </div>
          ) : (
            <></>
          )}
        </div>
      </>
      <div
        style={{
          padding: "20px",
          paddingTop: 0,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex" }}>
          <Select
            defaultValue="20"
            onChange={(value) => handlePageSize(value)}
            style={{
              marginTop: "10px",
              height: "20px",
              marginRight: "10px",
            }}
          >
            <Option value="20">20</Option>
            <Option value="50">50</Option>
            <Option value="100">100</Option>
            <Option value="200">200</Option>
            <Option value="500">500</Option>
          </Select>
          <span
            style={{
              marginTop: "16px",
              color: "#808080",
            }}
          >
            Page Size
          </span>
        </div>
        <Stack spacing={2} style={{ marginTop: "20px" }}>
          <Pagination
            count={totalPages}
            page={currentPage}
            variant="outlined"
            onChange={handlePageChange}
            color="primary"
          />
        </Stack>{" "}
      </div>
    </>
  );
}

export default TableComponent;
