// routes.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginForm from "./Components/LoginForm";
import NextPage from "./Components/NextPage";
import Firstpage from "./Pages/Dashboard";
import Employee from "./Employee";
import MainPage2 from "./Components/MainPage2";
import Partner from "./Pages/Partners/index";
import EditPartner from "./Pages/Partners/edit";
import EditPlan from "./Pages/Plans/edit";
import Plan from "./Pages/Plans";
import Master from "./Components/Master";
import AddMaster from "./Components/AddMaster";
import MasterValue from "./Components/MasterValue";
import MasterVal from "./Components/MasterVal";
import DropdownMenu from "./Components/DropdownMenu";
import Create from "./Pages/From/Create";
import View from "./Pages/From/View";
import AnimatedIcons from "./Components/AnimatedIcons";
import TodoList from "./TodoList";
import Content2 from "./Components/Content2";
import EditMaster from "./Components/EditMaster";
import Content3 from "./Components/Content3";
import AttendanceLogs from "./Pages/Attendance Logs";
import EmployeeReportsList from "./Pages/EmployeeReportsList";
import AllOrders from "./Pages/Orders/AllOrders";
import BuyersList2 from "./Pages/Buyers/BuyersList2";
import ManageMillers from "./Pages/Millers/ManageMillers";
import ManageExporters from "./Pages/Exporters/ManageExporters";
import Compleated from "./Components/Compleated";
import OverDew from "./Components/OverDew";
import Pendis from "./Components/Pendis";
import NewCall from "./Components/NewCall";
import ManageVendors from "./Pages/Vendors/ManageVendors";
import VendorsEdit from "./Pages/Vendors/VendorsEdit";
import EditMillers from "./Pages/Millers/EditMillers";
import ExportersEdit from "./Pages/Exporters/ExportersEdit";
import Reports from "./Pages/Sales CRM/Reports";
import HrDashboard from "./Hr View/Pages/HrDashboard";
import He from "./Pages/Sales CRM/page/he";
import AddBuyers from "./Pages/Buyers/AddBuyers/AddBuyers";
import DatelsLeads from "./Pages/Sales CRM/DatelsLeads";
import EditBuyers from "./Pages/Buyers/AddBuyers/EditBuyers";
import AddVendors from "./Pages/Vendors/AddVendors";
import AddExpor2 from "./Pages/Exporters/AddExpor2";
import AddExpor from "./Pages/Exporters/AddExpor";
import AddMillers from "./Pages/Millers/AddMillers";
import NewLeadsTable from "./Pages/Sales CRM/newLeadsTable";
import CustomPlan from "./Pages/Plans/customPlan";
import CustomPlans from "./Pages/Plans/CustomPlans";
import ResetPassword from "./Components/resetPassword";
import LeadContact from "./Pages/Sales CRM/leadContacts";
import LeadStatus from "./Pages/Sales CRM/page/LeadStatus";
import NewCalls from "./Pages/NewCalls";
import Customers from "./Pages/Customers/index1";
import Cases from "./Pages/Cases/Index2";
import Case from "./Pages/Case";
import CaseSubtype from "./Pages/CaseSubtype";
import EditCase from "./Pages/EditCase";
import CaseSubtypeValue from "./Pages/CaseSubtypeValue";
import AddCase from "./Pages/AddCase";
import CaseDetails from "./Pages/CaseDetails";
import Support from "./Pages/Support";
import AllCases from "./Pages/Cases/AllCases";
import MonthlyData from "./Pages/Customers/MonthlyReport";
import Pagination from "./Components/Pagination";
import InputForm from "./Pages/Cases/InputForm";
import Finance from "./Components/Finance";
import { DataProvider } from "./context/DataContext";
import CreateTicketByUrlCompanyId from "./Pages/createTicketCase.js/CreateTicketByUrlCompanyId";
import CaseListPageByUrl from "./Pages/Cases/CaseListPageByUrl";
import SupportByUrlCaseId from "./Pages/SupportByUrlCaseId";
import PrivateRoute from "./PrivateRoutes";
import PageNotFound from "./PageNotFound";
import SelectComponent from "./Pages/Sales CRM/SelectComponent";
import ClosureReport from "./Pages/Reports/SalesReport/ClosureReport";
import NotifiactionPopUp from "./Components/NotifiactionPopUp";
import PipelineReport from "./Pages/Reports/SalesReport/PipelineReport";
import LeadStatusReport from "./Pages/Reports/SalesReport/LeadStatusReport";
import ActivityReport from "./Pages/Reports/SalesReport/ActivityReport";
import SupportTicketStatusReport from "./Pages/Reports/ClientSupportKAMReport/SupportTicketStatusReport";
import ClientResponseTimeReport from "./Pages/Reports/ClientSupportKAMReport/ClientResponseTimeReport";
function AppRoutes() {
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <>
      {/* <NotifiactionPopUp /> */}
      <button
        onClick={scrollToBottom}
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          backgroundColor: "#000",
          color: "#fff",
          border: "none",
          borderRadius: "50%",
          width: "35px",
          height: "35px",
          fontSize: "24px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: "1000",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="white"
          width="24px"
          height="24px"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M12 16.5l-6-6h12z" />
        </svg>
      </button>
      <DataProvider>
        <Routes>
          <Route path="/" element={<LoginForm />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/nextPage" element={<NextPage />} />

          <Route path="/all-case/:customerId" element={<CaseListPageByUrl />} />
          <Route
            path="/create-case/:companyId"
            element={<CreateTicketByUrlCompanyId />}
          />
          <Route
            path="/support-case/:caseId"
            element={<SupportByUrlCaseId />}
          />
          <Route
            path="/Dashboard"
            element={
              <PrivateRoute>
                <Firstpage />
              </PrivateRoute>
            }
          />
          <Route
            path="/Employee"
            element={
              <PrivateRoute>
                <Employee />
              </PrivateRoute>
            }
          />
          <Route
            path="/Create/:empid"
            element={
              <PrivateRoute>
                <Create />
              </PrivateRoute>
            }
          />
          <Route
            path="/View"
            element={
              <PrivateRoute>
                <View />
              </PrivateRoute>
            }
          />
          <Route
            path="/MainPage2"
            element={
              <PrivateRoute>
                <MainPage2 />
              </PrivateRoute>
            }
          />
          <Route
            path="/Master"
            element={
              <PrivateRoute>
                <Master />
              </PrivateRoute>
            }
          />
          <Route
            path="/MasterValue"
            element={
              <PrivateRoute>
                <MasterValue />
              </PrivateRoute>
            }
          />
          <Route
            path="/MasterVal"
            element={
              <PrivateRoute>
                <MasterVal />
              </PrivateRoute>
            }
          />
          <Route
            path="/AddMaster"
            element={
              <PrivateRoute>
                <AddMaster />
              </PrivateRoute>
            }
          />
          <Route
            path="/dropdown"
            element={
              <PrivateRoute>
                <DropdownMenu />
              </PrivateRoute>
            }
          />
          <Route
            path="/icons"
            element={
              <PrivateRoute>
                <AnimatedIcons />
              </PrivateRoute>
            }
          />
          <Route
            path="/todos"
            element={
              <PrivateRoute>
                <TodoList />
              </PrivateRoute>
            }
          />
          <Route
            path="/Content2/:empid"
            element={
              <PrivateRoute>
                <Content2 />
              </PrivateRoute>
            }
          />
          <Route
            path="/EditMaster/:empid"
            element={
              <PrivateRoute>
                <EditMaster />
              </PrivateRoute>
            }
          />
          <Route
            path="/Content3/:empid"
            element={
              <PrivateRoute>
                <Content3 />
              </PrivateRoute>
            }
          />
          <Route
            path="/DatelsLeads/:empid"
            element={
              <PrivateRoute>
                <DatelsLeads />
              </PrivateRoute>
            }
          />
          <Route
            path="/EditMillers/:empid"
            element={
              <PrivateRoute>
                <EditMillers />
              </PrivateRoute>
            }
          />
          <Route
            path="/VendorsEdit/:empid"
            element={
              <PrivateRoute>
                <VendorsEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/ExportersEdit/:empid"
            element={
              <PrivateRoute>
                <ExportersEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/AttendanceLogs"
            element={
              <PrivateRoute>
                <AttendanceLogs />
              </PrivateRoute>
            }
          />
          <Route
            path="/EmployeeReportsList"
            element={
              <PrivateRoute>
                <EmployeeReportsList />
              </PrivateRoute>
            }
          />
          <Route
            path="/AllOrders"
            element={
              <PrivateRoute>
                <AllOrders />
              </PrivateRoute>
            }
          />
          <Route
            path="/BuyersList2"
            element={
              <PrivateRoute>
                <BuyersList2 />
              </PrivateRoute>
            }
          />
          <Route
            path="/ManageMillers"
            element={
              <PrivateRoute>
                <ManageMillers />
              </PrivateRoute>
            }
          />
          <Route
            path="/ManageExporters"
            element={
              <PrivateRoute>
                <ManageExporters />
              </PrivateRoute>
            }
          />
          <Route
            path="/ManageVendors"
            element={
              <PrivateRoute>
                <ManageVendors />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/completed"
            element={
              <PrivateRoute>
                <Compleated />
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/:status"
            element={
              <PrivateRoute>
                <OverDew />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/pending"
            element={
              <PrivateRoute>
                <Pendis />
              </PrivateRoute>
            }
          /> */}
          {/* <Route
            path="/NewCall"
            element={
              <PrivateRoute>
                <NewCall />
              </PrivateRoute>
            }
          /> */}
          {/* <Route
            path="/NewLeads"
            element={
              <PrivateRoute>
                <NewLeadsTable />
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/leads/:status"
            element={
              <PrivateRoute>
                <NewLeadsTable />
              </PrivateRoute>
            }
          />
          <Route
            path="/cases/:status"
            element={
              <PrivateRoute>
                <Cases />
              </PrivateRoute>
            }
          />
          <Route
            path="/closure-report"
            element={
              <PrivateRoute>
                <ClosureReport />
              </PrivateRoute>
            }
          />
          <Route
            path="/pipeline-report"
            element={
              <PrivateRoute>
                <PipelineReport />
              </PrivateRoute>
            }
          />
          <Route
            path="/lead-status-report"
            element={
              <PrivateRoute>
                <LeadStatusReport />
              </PrivateRoute>
            }
          />
          <Route
            path="/activity-report"
            element={
              <PrivateRoute>
                <ActivityReport />
              </PrivateRoute>
            }
          />
          <Route
            path="/support-ticket-report"
            element={
              <PrivateRoute>
                <SupportTicketStatusReport />
              </PrivateRoute>
            }
          />
          <Route
            path="/client-response-report"
            element={
              <PrivateRoute>
                <ClientResponseTimeReport />
              </PrivateRoute>
            }
          />
          <Route
            path="/partners"
            element={
              <PrivateRoute>
                <Partner />
              </PrivateRoute>
            }
          />
          <Route
            path="/customers"
            element={
              <PrivateRoute>
                <Customers />
              </PrivateRoute>
            }
          />
          <Route
            path="/partner/:id"
            element={
              <PrivateRoute>
                <EditPartner />
              </PrivateRoute>
            }
          />
          <Route
            path="/lead/contacts/:id"
            element={
              <PrivateRoute>
                <LeadContact />
              </PrivateRoute>
            }
          />
          <Route
            path="/lead/status/:id"
            element={
              <PrivateRoute>
                <LeadStatus />
              </PrivateRoute>
            }
          />
          <Route
            path="/plans"
            element={
              <PrivateRoute>
                <Plan />
              </PrivateRoute>
            }
          />
          <Route
            path="/plans/:id"
            element={
              <PrivateRoute>
                <EditPlan />
              </PrivateRoute>
            }
          />
          <Route
            path="/customPlan/:id"
            element={
              <PrivateRoute>
                <CustomPlan />
              </PrivateRoute>
            }
          />
          <Route
            path="/custom-plans"
            element={
              <PrivateRoute>
                <CustomPlans />
              </PrivateRoute>
            }
          />
          <Route
            path="/Reports"
            element={
              <PrivateRoute>
                <Reports />
              </PrivateRoute>
            }
          />
          <Route
            path="/HrDashboard"
            element={
              <PrivateRoute>
                <HrDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/he"
            element={
              <PrivateRoute>
                <He />
              </PrivateRoute>
            }
          />
          <Route
            path="/AddBuyers"
            element={
              <PrivateRoute>
                <AddBuyers />
              </PrivateRoute>
            }
          />
          <Route
            path="/EditBuyers/:empid"
            element={
              <PrivateRoute>
                <EditBuyers />
              </PrivateRoute>
            }
          />
          <Route
            path="/AddVendors"
            element={
              <PrivateRoute>
                <AddVendors />
              </PrivateRoute>
            }
          />
          <Route
            path="/AddExpor2"
            element={
              <PrivateRoute>
                <AddExpor2 />
              </PrivateRoute>
            }
          />
          <Route
            path="/AddExpor"
            element={
              <PrivateRoute>
                <AddExpor />
              </PrivateRoute>
            }
          />
          <Route
            path="/AddMillers"
            element={
              <PrivateRoute>
                <AddMillers />
              </PrivateRoute>
            }
          />
          <Route
            path="/new-calls"
            element={
              <PrivateRoute>
                <NewCalls />
              </PrivateRoute>
            }
          />
          <Route
            path="/case-type"
            element={
              <PrivateRoute>
                <Case />
              </PrivateRoute>
            }
          />
          <Route
            path="/case-subtype"
            element={
              <PrivateRoute>
                <CaseSubtype />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-case/:empid"
            element={
              <PrivateRoute>
                <EditCase />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-case"
            element={
              <PrivateRoute>
                <AddCase />
              </PrivateRoute>
            }
          />
          <Route
            path="/case-value"
            element={
              <PrivateRoute>
                <CaseSubtypeValue />
              </PrivateRoute>
            }
          />
          <Route
            path="/case-detail/:empid"
            element={
              <PrivateRoute>
                <CaseDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/support/:caseId"
            element={
              <PrivateRoute>
                <Support />
              </PrivateRoute>
            }
          />
          <Route
            path="/allcases/:caseID"
            element={
              <PrivateRoute>
                <AllCases />
              </PrivateRoute>
            }
          />
          <Route
            path="/report/:customerId"
            element={
              <PrivateRoute>
                <MonthlyData />
              </PrivateRoute>
            }
          />
          <Route
            path="/all-report"
            element={
              <PrivateRoute>
                <MonthlyData />
              </PrivateRoute>
            }
          />
          <Route
            path="/pagination"
            element={
              <PrivateRoute>
                <Pagination />
              </PrivateRoute>
            }
          />
          <Route
            path="/example"
            element={
              <PrivateRoute>
                <InputForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/customers"
            element={
              <PrivateRoute>
                <Customers />
              </PrivateRoute>
            }
          />
          <Route
            path="/finance"
            element={
              <PrivateRoute>
                <Finance />
              </PrivateRoute>
            }
          />
          <Route
            path="/select"
            element={
              <PrivateRoute>
                <SelectComponent />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </DataProvider>
    </>
  );
}

export default AppRoutes;
