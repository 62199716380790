import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import NewLeads from "../Pages/Sales CRM/NewLeads";
import { toast } from "react-toastify";
import EditLead from "../Pages/Sales CRM/EditLead";
import axios from "axios";
import Loader from "../assets/img/loader.gif";
import spiner from "../assets/img/spiner.gif";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format, set } from "date-fns";
import LeadStatus from "../Pages/Sales CRM/page/LeadStatus";
import { useLocation } from "react-router-dom";
import "../Pages/Sales CRM/new.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useCallback } from "react";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { DataContext } from "../context/DataContext";
import SelectComponent from "../Pages/Sales CRM/SelectComponent";
import _ from "lodash";
let num = 0;
function NewLeadsTable() {
  console.log("aman", num++);
  const apiUrl = process.env.REACT_APP_URL;
  const Token = localStorage.getItem("Token");
  const navigate = useNavigate();
  const result = useContext(DataContext);
  const location = useLocation();
  const [stageStatus, setStageStatus] = useState("");
  const [leadId, setLeadId] = useState([]);
  const [employee, setEmployee] = useState({});
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [modelOPen, setModalOpen] = useState(false);
  const [modelOPen2, setModalOpen2] = useState(false);
  const [modelOPen3, setModalOpen3] = useState(false);
  const [modelOPen4, setModalOpen4] = useState(false);
  const [statusModalOPen, setStatusModalOpen] = useState(false);
  const [dateFilterModalOPen, setDateFilterModalOpen] = useState(false);
  const [userType, setUserType] = useState({});
  const [employees, setEmployees] = useState([]);
  const [newStatus, setNewStatus] = useState("");
  const [newStatusLeadId, setNewStatusLeadId] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [sourceTo, setSourceTo] = useState("");
  const [stageTo, setStageTo] = useState("");
  const [leadPipelineTo, setPipelineTo] = useState("");
  const [bulkAssignId, setBulkAssignId] = useState([]);
  const [assignedLeadId, setAssignedLeadId] = useState("");
  const { status } = useParams();
  const [loader, setLoader] = useState(true);
  const [options, setOptions] = useState([]);
  const [selectedKamTypeOption, setSelectedKamTypeOption] = useState("");
  const [leadStatusOptions, setLeadStatusOptions] = useState([]);
  const [filterByLeadSource, setFilterByLeadSource] = useState("");
  const [filterByLeadPipeline, setFilterByLeadPipeline] = useState("");
  const [filterByBusinessCategory, setFilterByBusinessCategory] = useState("");
  const [filterByShipmentPotential, setFilterByShipmentPotential] =
    useState("");
  const [filterByClientPersona, setFilterByClientPersona] = useState("");
  const [filterByIskycDone, setFilterByIskycDone] = useState("");
  const [filterByEmployeeId, setFilterByEmployeeId] = useState([]);
  const [filterByPartner, setFilterByPartner] = useState("");
  const [employeeIdOfLeadOwner, setEmployeeIdOfLeadOwner] = useState("");
  const [filterByGenericEmailPattern, setFilterByGenericEmailPattern] =
    useState(false);
  const [kamType, setKamType] = useState([]);
  const [kamEmployee, setKamEmployee] = useState([]);
  const [filterByObj, setFilterByObj] = useState({
    leadSource: "",
    leadPipeline: "",
    businessCategory: "",
    shipmentPotential: "",
    clientPersona: "",
    partner: "",
    to: "",
    from: "",
    search: "",
    isKycDone: "",
    minimumExpectedShipmentToFship: "",
    employeeId: "",
    genericEmailPattern: "",
  });
  const { stage } = location.state || {};
  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [linkActive, setLinkActive] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [applyFilter, setApplyFilter] = useState(false);
  const [leadSourceOptions, setLeadSourceOptions] = useState([]);
  const [leadPipelineOptions, setLeadPipelineOptions] = useState([]);
  const [businessCategoryOptions, setBusinessCategoryOptions] = useState([]);
  const [shipmentPotentialOptions, setShipmentPotentialOptions] = useState([]);
  const [clientPersonaOptions, setClientPersonaOptions] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [isKycDone, setIsKycDone] = useState([]);
  const [employeeId, setEmployeeId] = useState([]);
  const [from, setFrom] = useState("");
  const [kamTypeEmployee, setKamTypeEmployee] = useState("");
  const [leadData, setLeadData] = useState({});
  const [leadData1, setLeadData1] = useState({});
  const [IsUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [IsWonModalOpen, setIsWonModalOpen] = useState(false);
  const [IsUpdateModalOpen1, setIsUpdateModalOpen1] = useState(false);
  const filteredUsers = filteredLeads.filter((user) => user);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleDateChange = useCallback(
    (ranges) => {
      const formatToISOString = (date) => {
        return new Date(
          date.getTime() - date.getTimezoneOffset() * 60000
        ).toISOString();
      };
      const startDate = new Date(ranges.selection.startDate);
      const endDate = new Date(ranges.selection.endDate);
      setFilterByObj({
        ...filterByObj,
        to: formatToISOString(endDate),
        from: formatToISOString(startDate),
      });
      setDate(ranges.selection);
    },
    [date]
  );
  const handleDateFilterClick = () => {
    setDateFilterModalOpen(true);
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    scrollToTop();
  };
  const handlePageSize = (value) => {
    setPageSize(value.target.innerText);
    scrollToTop();
  };

  const handleCheckboxChange2 = useCallback(
    (selected) => {
      setFilterByBusinessCategory(
        selected ? selected.map((option) => option.value) : []
      );
    },
    [filterByBusinessCategory]
  );
  const handleCheckboxChange3 = useCallback(
    (selected) => {
      setFilterByPartner(
        selected ? selected.map((option) => option.value) : []
      );
    },
    [filterByPartner]
  );
  const handleCheckboxChange4 = useCallback(
    (selected) => {
      setFilterByShipmentPotential(
        selected ? selected.map((option) => option.value) : []
      );
    },
    [filterByShipmentPotential]
  );

  const handleCheckboxChange5 = useCallback(
    (selected) => {
      setFilterByClientPersona(
        selected ? selected.map((option) => option.value) : []
      );
    },
    [filterByClientPersona]
  );
  const handleCheckboxChange6 = useCallback(
    (selected) => {
      setFilterByIskycDone(
        selected ? selected.map((option) => option.value) : []
      );
    },
    [filterByIskycDone]
  );
  const handleCheckboxChange7 = useCallback(
    (selected) => {
      setFilterByEmployeeId(
        selected ? selected.map((option) => option.value) : []
      );
      setEmployeeIdOfLeadOwner(
        selected ? selected.map((option) => option.id) : []
      );
    },
    [filterByEmployeeId]
  );
  const handleCheckboxChange = useCallback(
    (selected) => {
      setFilterByLeadSource(
        selected ? selected.map((option) => option.value) : []
      );
    },
    [filterByLeadSource]
  );
  const handleCheckboxChange1 = useCallback(
    (selected) => {
      setFilterByLeadPipeline(
        selected ? selected.map((option) => option.value) : []
      );
    },
    [filterByLeadPipeline]
  );

  const handleCheckboxChangeCheck = useCallback(
    (event) => {
      setFilterByGenericEmailPattern(event.target.checked);
      setFilterByObj({
        ...filterByObj,
        genericEmailPattern: event.target.checked,
      });
    },
    [filterByGenericEmailPattern]
  );

  const updateFilterByObj = (newState) => {
    if (!_.isEqual(filterByObj, newState)) {
      setFilterByObj(newState);
    }
  };

  const loadcontent = (id) => {
    navigate(`/DatelsLeads/${id}`);
  };

  const handleOpenModal = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
    document.body.classList.add("modal-open");
    const {
      leadSource,
      leadPipeline,
      businessCategory,
      partner,
      shipmentPotential,
      clientPersona,
      minimumExpectedShipmentToFship,
      employeeId,
      isKycDone,
      from,
      to,
    } = filterByObj;
    const url = `${apiUrl}/lead/exportLead?id=&mobileNumber=&emailId=&leadSource=${leadSource}&leadPipeline=${leadPipeline}&businessCategory=${businessCategory}&currentShippingPartner=${partner}&shipmentPotential=${shipmentPotential}&clientPersona=${clientPersona}&minimumExpectedShipmentToFship=${minimumExpectedShipmentToFship}&employeeId=${employeeId}&isKycDone=${isKycDone}&from=${from}&to=${to}&type=${status}`;
    fetch(url, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          // console.log(data.data.fileURL);
          setFrom(data.data.fileURL);
        }
      });
  };
  const handleConfirm = () => {
    window.location.href = from;

    setIsModalOpen(false);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const handleKamEmployee = (e) => {
    setKamTypeEmployee(e.target.value);
  };
  const handleAssignKam = () => {
    postData();
  };

  useEffect(() => {
    setEmployee(result.employeeData);
    setUserType(result.employeeData);
  });

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/13`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setKamType(data.data);
        }
      });
  }, []);

  useEffect(() => {
    const newFilterByObj = {
      leadSource: filterByLeadSource,
      leadPipeline: filterByLeadPipeline,
      businessCategory: filterByBusinessCategory,
      shipmentPotential: filterByShipmentPotential,
      clientPersona: filterByClientPersona,
      partner: filterByPartner,
      to: "",
      from: "",
      search: search,
      isKycDone: filterByIskycDone,
      minimumExpectedShipmentToFship: "",
      employeeId: employeeIdOfLeadOwner,
      genericEmailPattern: filterByGenericEmailPattern,
    };

    updateFilterByObj(newFilterByObj);
  }, [
    filterByLeadSource,
    filterByLeadPipeline,
    filterByBusinessCategory,
    filterByClientPersona,
    filterByPartner,
    filterByShipmentPotential,
    filterByIskycDone,
    filterByEmployeeId,
    filterByGenericEmailPattern,
  ]);

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/1`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setLeadSourceOptions(data.data);
        }
      });
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/21`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setIsKycDone(data.data);
        } else {
        }
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (selectedKamTypeOption) {
      // Make an API request to fetch gender options
      fetch(`${apiUrl}/employee/kamEmp?kycType=${selectedKamTypeOption}`, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data && Array.isArray(data.data)) {
            setKamEmployee(data.data);
          } else {
          }
        })
        .catch((error) => {});
    }
  }, [selectedKamTypeOption]);
  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/2`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setLeadPipelineOptions(data.data);
        } else {
        }
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/3`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setBusinessCategoryOptions(data.data);
        } else {
        }
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/20`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setOptions(data.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/employee/empDropdownSub`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setEmployeeId(data.data);
        }
      })
      .catch((error) => {});
  }, []);
  useEffect(() => {
    setEmployee(result.employeeData);
  });
  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/4`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setShipmentPotentialOptions(data.data);
        }
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/5`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setClientPersonaOptions(data.data);
        }
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/11`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPartnerOptions(data.data);
      });
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/8`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setLeadStatusOptions(data.data);
        }
      });
  }, []);

  useEffect(() => {
    const Token = localStorage.getItem("Token");
    fetch(`${apiUrl}/employee/employees`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            setEmployees(data.data);
          }
        }
      });
  }, []);

  const updateStatus = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("status", newStatus);
    const response = await fetch(
      `${apiUrl}/lead/changeStatus/${newStatusLeadId}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      }
    );
    if (!response.ok) {
      toast.error(response.message);
    }
    setModalOpen(false);
    toast.success("Status Successfully Updated");
    window.location.reload();
  };
  const handleAssign = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("empId", assignedTo);
    if (bulkAssignId.length) {
      formDataToSend.append("leadsId", JSON.stringify(bulkAssignId));
      const response = await fetch(`${apiUrl}/lead/assignManyLead`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setModalOpen(false);
      const data = await response.json();
      toast.success("Assigned successfully");
      window.location.reload();
      return;
    }
    const response = await fetch(
      `${apiUrl}/lead/assignedLead/${assignedLeadId}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    setModalOpen(false);
    window.location.reload();
  };
  const handleAssign2 = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("leadSource", sourceTo);
    // console.log(bulkAssignId);
    if (bulkAssignId.length) {
      formDataToSend.append("leadArray", JSON.stringify(bulkAssignId));
      const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setModalOpen2(false);
      toast.success("Assigned successfully");
      window.location.reload();
      return;
    }
    const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Token}`,
      },
      body: formDataToSend,
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    setModalOpen2(false);
    const data = await response.json();
    toast.success("Assigned successfully");
    window.location.reload();
  };
  const handleAssign3 = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("stage", stageTo);
    if (bulkAssignId.length) {
      formDataToSend.append("leadArray", JSON.stringify(bulkAssignId));
      const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setModalOpen3(false);
      const data = await response.json();
      toast.success("Assigned successfully");
      window.location.reload();
      return;
    }
    const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Token}`,
      },
      body: formDataToSend,
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    setModalOpen3(false);
    const data = await response.json();
    toast.success("Assigned successfully");
    window.location.reload();
  };
  const handleAssign4 = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("leadPipeline", leadPipelineTo);
    if (bulkAssignId.length) {
      formDataToSend.append("leadArray", JSON.stringify(bulkAssignId));
      const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setModalOpen4(false);
      const data = await response.json();
      toast.success("Assigned successfully");
      window.location.reload();
      return;
    }
    const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Token}`,
      },
      body: formDataToSend,
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    setModalOpen4(false);
    const data = await response.json();
    toast.success("Assigned successfully");
    window.location.reload();
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setIsModalOpen(false);
    window.location.reload();
  };
  const handleCloseModal2 = () => {
    setModalOpen2(false);
    setModalOpen2(false);
  };
  const handleCloseModal3 = () => {
    setModalOpen3(false);
    setModalOpen3(false);
  };
  const handleCloseModal4 = () => {
    setModalOpen4(false);
    setModalOpen4(false);
  };
  const handleCloseStatusModal = () => {
    setStatusModalOpen(false);
  };

  const handleCloseDateFilterModal = () => {
    setDateFilterModalOpen(false);
  };
  const handleWonCloseModal = () => {
    setIsWonModalOpen(false);
  };
  const handleChange = (event) => {
    setSelectedKamTypeOption(event.target.value);
  };

  async function postData() {
    try {
      const response = await fetch(
        `${apiUrl}/customer/addCustomer?empId=${kamTypeEmployee}&leadId=${leadId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
          body: JSON.stringify({
            id: { kamTypeEmployee },
            leadId: { leadId },
          }),
        }
      );
      const data = await response.json();
      if (data.status === "success") {
        toast.success(data.message);
        navigate("/customers");
      } else {
        toast.error(data.message);
      }
    } catch (error) {}
  }
  const handleSetEditLead = (e) => {
    e.preventDefault();
    const lead = JSON.parse(e.target.dataset.lead);
    setIsUpdateModalOpen(true);
    setLeadData(lead);
  };
  const capitalizeFirstLetterOfEachWord = (str) => {
    return str?.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const handleWonLead = (e) => {
    e.preventDefault();
    setIsWonModalOpen(true);
  };

  const handleLeadStatus = (e) => {
    e.preventDefault();
    const lead = JSON.parse(e.target.dataset.lead);
    setIsUpdateModalOpen1(true);
    setLeadData1(lead);
  };
  const closeEditModal = () => {
    setIsUpdateModalOpen(false);
  };
  const closeEditModal1 = () => {
    setIsUpdateModalOpen1(false);
  };
  const handleStatusChange = useCallback(
    async (stage) => {
      const url = `${apiUrl}/lead/getActivityLead?leadSource=${filterByObj.leadSource}&leadPipeline=${filterByObj.leadPipeline}&businessCategory=${filterByObj.businessCategory}&currentShippingPartner=${filterByObj.partner}&shipmentPotential=${filterByObj.shipmentPotential}&clientPersona=${filterByObj.clientPersona}&to=${filterByObj.to}&from=${filterByObj.from}&search=${filterByObj.search}&isKycDone=${filterByObj.isKycDone}&minimumExpectedShipmentToFship=${filterByObj.minimumExpectedShipmentToFship}&employeeId=${filterByObj.employeeId}&genericEmailPattern=${filterByObj.genericEmailPattern}&page=${currentPage}&pageSize=${pageSize}&type=${status}`;
      setLoader(true);

      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${Token}`,
            "Content-Type": "application/json",
          },
        });

        const data = response.data;

        if (data.status === "success" && Array.isArray(data.data)) {
          setFilteredLeads(data.data);
          setTotalPages(Math.ceil(data.totalCount / pageSize));
          // setCurrentPage(1);
          setLoaded(true);
        }
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoader(false);
      }
    },
    [status, currentPage, pageSize, applyFilter]
  );

  useEffect(() => {
    handleStatusChange(status);
  }, [status, currentPage, pageSize, applyFilter]);

  const handleApplyFilter = () => {
    setApplyFilter(!applyFilter);
  };
  return (
    <div>
      {" "}
      <div className="page">
        <div className="">
          <NewLeads employeeData={employee} />
          {IsUpdateModalOpen && (
            <EditLead myLead={leadData} onClose={closeEditModal} />
          )}
          {IsUpdateModalOpen1 && (
            <LeadStatus myLead={leadData1} onClose={closeEditModal1} />
          )}
          <div style={{ marginTop: "150px" }}>
            <div className="">
              <div className="col-lg-12">
                <div className="card custom-card">
                  <div className="card-body py-3">
                    <div className="row">
                      <div className="col-sm-4 mt-3">
                        <label>Search Here</label>
                        <input
                          type="search"
                          className="form-control"
                          placeholder="Search..."
                          aria-controls="example1"
                          onChange={(e) => {
                            setSearch(e.target.value);

                            setFilterByObj({
                              ...filterByObj,
                              search: e.target.value,
                            });
                          }}
                          style={{ border: "1px Solid #8E3E63" }}
                        />
                      </div>
                      <div className="col-sm-2 mt-3">
                        <label>Date Filter</label>
                        <button
                          style={{
                            fontSize: "12px",
                            width: "220px",
                          }}
                          onClick={handleDateFilterClick}
                          className="btn btn-outline-success"
                        >{`${format(date.startDate, "MMM dd yyyy")} -- ${format(
                          date.endDate,
                          "MMM dd yyyy"
                        )}`}</button>
                      </div>
                      <SelectComponent
                        label={"Lead Source"}
                        options={leadSourceOptions}
                        handleSelectChange={handleCheckboxChange}
                        selectedOptions={filterByLeadSource}
                      ></SelectComponent>

                      <SelectComponent
                        label={"Lead Pipeline"}
                        options={leadPipelineOptions}
                        handleSelectChange={handleCheckboxChange1}
                        selectedOptions={filterByLeadPipeline}
                      ></SelectComponent>

                      <SelectComponent
                        label={"Business Category"}
                        options={businessCategoryOptions}
                        handleSelectChange={handleCheckboxChange2}
                        selectedOptions={filterByBusinessCategory}
                      ></SelectComponent>

                      <SelectComponent
                        label={"Shipping Partner"}
                        options={partnerOptions}
                        handleSelectChange={handleCheckboxChange3}
                        selectedOptions={filterByPartner}
                      ></SelectComponent>

                      <SelectComponent
                        label={"Shipment Potential"}
                        options={shipmentPotentialOptions}
                        handleSelectChange={handleCheckboxChange4}
                        selectedOptions={filterByShipmentPotential}
                      ></SelectComponent>

                      <SelectComponent
                        label={"Client Persona"}
                        options={clientPersonaOptions}
                        handleSelectChange={handleCheckboxChange5}
                        selectedOptions={filterByClientPersona}
                      ></SelectComponent>
                      <>
                        <SelectComponent
                          label={"Lead Owner"}
                          options={employeeId}
                          handleSelectChange={handleCheckboxChange7}
                          selectedOptions={filterByEmployeeId}
                        ></SelectComponent>
                      </>

                      <div className="col-sm-2 mt-3">
                        <label>Minimum Expected Shipment</label>
                        <input
                          style={{
                            paddingRight: "-0.25rem",
                            border: "1px solid #FFFF80",
                          }}
                          type="search"
                          className="form-control"
                          placeholder="Minimum Expected Shipment..."
                          aria-controls="example1"
                          onChange={(e) => {
                            setSearch(e.target.value);
                            setFilterByObj({
                              ...filterByObj,
                              minimumExpectedShipmentToFship: e.target.value,
                            });
                          }}
                        />
                      </div>

                      <SelectComponent
                        label={"KYC"}
                        options={isKycDone}
                        handleSelectChange={handleCheckboxChange6}
                        selectedOptions={filterByIskycDone}
                      ></SelectComponent>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "20px",
                        }}
                      >
                        <div style={{ marginTop: "10px" }}>
                          <input
                            type="checkbox"
                            id="disableGenericEmail"
                            name="disableGenericEmail"
                            checked={filterByGenericEmailPattern}
                            onChange={handleCheckboxChangeCheck}
                          />{" "}
                          <label htmlFor="disableGenericEmail">
                            Disable Generic Email
                          </label>
                        </div>

                        <div>
                          <button
                            style={{ marginLeft: "12px" }}
                            onClick={handleApplyFilter}
                            className="btn btn-outline-secondary"
                          >
                            Apply Filter
                          </button>
                        </div>
                        <div>
                          <button
                            style={{ marginLeft: "12px" }}
                            onClick={() => {
                              setFilterByObj({
                                leadSource: "",
                                leadPipeline: "",
                                businessCategory: "",
                                shipmentPotential: "",
                                clientPersona: "",
                                partner: "",
                                to: "",
                                from: "",
                                isKycDone: "",
                                genericEmailPattern: "",
                                employeeId: "",
                                minimumExpectedShipmentToFship: "",
                              });
                              setDate({
                                startDate: new Date(),
                                endDate: new Date(),
                                key: "selection",
                              });
                              window.location.reload();
                            }}
                            className="btn btn-outline-secondary"
                          >
                            Clear Filter
                          </button>
                        </div>

                        {/* <div>
                          <button
                            onClick={handleOpenModal}
                            style={{ marginLeft: "12px" }}
                            className="btn btn-outline-info"
                          >
                            Export
                          </button>
                          <div
                            className={`modal ${
                              isModalOpen ? "show" : ""
                            } bg-black-7`}
                            style={{ display: isModalOpen ? "block" : "none" }}
                            tabIndex="-1"
                            role="dialog"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered modal-xl"
                              role="document"
                            >
                              <div className="modal-content rounded-3">
                                <div className="modal-header">
                                  <h5 className="modal-title">
                                    download excel
                                  </h5>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleCloseModal}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>

                                <div
                                  className="modal-body "
                                  style={{ width: "100%" }}
                                >
                                  {from !== "" ? (
                                    <p>Click here to download</p>
                                  ) : (
                                    <p>
                                      Please wait , Download button will appear
                                      soon...{" "}
                                    </p>
                                  )}
                                  {from !== "" ? (
                                    <button
                                      className="btn ripple btn-primary"
                                      type="button"
                                      onClick={handleConfirm}
                                    >
                                      Download
                                    </button>
                                  ) : (
                                    <img src={spiner} height={"50px"}></img>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div>
                          <div
                            className={`modal ${
                              dateFilterModalOPen ? "show" : ""
                            } bg-black-7`}
                            style={{
                              display: dateFilterModalOPen ? "block" : "none",
                            }}
                            tabIndex="-1"
                            role="dialog"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered modal-xl"
                              role="document"
                            >
                              <div className="modal-content rounded-3">
                                <div className="modal-header">
                                  <h5 className="modal-title">
                                    Select Date Range
                                  </h5>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleCloseDateFilterModal}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div
                                  className="modal-body"
                                  style={{ width: "100%" }}
                                >
                                  <DateRangePicker
                                    ranges={[date]}
                                    onChange={handleDateChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`modal bg-black-7 ${
                              IsWonModalOpen ? "show" : ""
                            }`}
                            style={{
                              display: IsWonModalOpen ? "block" : "none",
                            }}
                            tabIndex="-1"
                            role="dialog"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered modal-xl"
                              role="document"
                            >
                              <div className="modal-content rounded-3">
                                <div className="modal-header">
                                  <h5 className="modal-title">
                                    Assigned To Customer
                                  </h5>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleWonCloseModal}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div
                                    className="modal-body"
                                    style={{
                                      width: "20px",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    <label>Select KamType</label>
                                    <select
                                      style={{
                                        height: "4vh",
                                        display: "inline",

                                        padding: "10px",
                                        borderRadius: "5px",
                                        border: "1px solid #808080",
                                        transition: "box-shadow 0.3s ease",
                                        outline: "none  ",
                                        width: "200px",
                                        height: "45px",
                                        color: "#808080",
                                        background: "#fff",
                                      }}
                                      placeholder="Select"
                                      type="text"
                                      value={selectedKamTypeOption}
                                      onChange={handleChange}
                                    >
                                      <option>Select</option>
                                      {options.map((option) => (
                                        <option
                                          key={option.id}
                                          value={option.name}
                                        >
                                          {option.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div
                                    className="modal-body"
                                    style={{ width: "20px", paddingLeft: 0 }}
                                  >
                                    <label>Select Employee</label>
                                    <select
                                      style={{
                                        height: "4vh",
                                        display: "inline",

                                        padding: "10px",
                                        borderRadius: "5px",
                                        border: "1px solid #808080",
                                        transition: "box-shadow 0.3s ease",
                                        outline: "none  ",
                                        width: "200px",
                                        height: "45px",
                                        color: "#808080",
                                        background: "#fff",
                                      }}
                                      placeholder="Select"
                                      type="text"
                                      value={kamTypeEmployee}
                                      onChange={handleKamEmployee}
                                    >
                                      <option>Select</option>
                                      {kamEmployee.map((option) => (
                                        <option
                                          key={option.id}
                                          value={option.id}
                                        >
                                          {option.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <button
                                    className="calender btn btn-info"
                                    onClick={handleAssignKam}
                                    style={{
                                      height: "30px",
                                      marginTop: "55px",
                                      marginRight: "50px",
                                      backgroundColor: "#032863",
                                    }}
                                  >
                                    Create Customer
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {loader ? (
                        <>
                          {" "}
                          <div className="loading-overlay">
                            <img src={Loader} alt="Loading..." />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>{" "}
                  </div>
                </div>
              </div>{" "}
            </div>
            {/* Row */}
            <div className="">
              <div className="col-lg-12">
                <div className="card custom-card">
                  <div className="card-body">
                    <div className="text-wrap">
                      <div className="panel panel-primary tabs-style-3 p-0 tabs-style-3-0">
                        <div className="tab-menu-heading">
                          <div className="tabs-menu ">
                            {/* Tabs */}

                            {bulkAssignId.length ? (
                              <a
                                // href=""
                                className="btn ripple btn-info btn-sm m-2 text-center"
                                onClick={() => {
                                  setModalOpen(true);
                                }}
                              >
                                Bulk Assign
                              </a>
                            ) : (
                              <></>
                            )}
                            {bulkAssignId.length ? (
                              <a
                                // href=""
                                className="btn ripple btn-danger btn-sm m-2 text-center"
                                onClick={() => {
                                  setModalOpen2(true);
                                }}
                              >
                                Bulk Source
                              </a>
                            ) : (
                              <></>
                            )}
                            {bulkAssignId.length ? (
                              <a
                                // href=""
                                className="btn ripple btn-success btn-sm m-2 text-center"
                                onClick={() => {
                                  setModalOpen3(true);
                                }}
                              >
                                Bulk Stage
                              </a>
                            ) : (
                              <></>
                            )}
                            {bulkAssignId.length ? (
                              <a
                                // href=""
                                className="btn ripple btn-warning btn-sm m-2 text-center"
                                onClick={() => {
                                  setModalOpen4(true);
                                }}
                              >
                                Bulk Pipeline
                              </a>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white m-3 rounded-3 p-4">
            {loaded ? (
              <>
                <div className="table-responsive">
                  <table className="table table-striped table-bordered text-nowrap mb-0">
                    <thead>
                      <tr>
                        {userType.userType === "1" && (
                          <th>
                            <input
                              type="checkbox"
                              id="bulkSelect"
                              onChange={(e) => {
                                let newArr = [];
                                const allLeadsCheck =
                                  document.getElementsByClassName("selectLead");
                                if (e.target.checked) {
                                  for (
                                    let i = 0;
                                    i < filteredUsers.length;
                                    i++
                                  ) {
                                    newArr.push(filteredUsers[i].id);
                                  }
                                  setBulkAssignId(newArr);
                                  for (
                                    let i = 0;
                                    i < allLeadsCheck.length;
                                    i++
                                  ) {
                                    allLeadsCheck[i].checked = true;
                                  }
                                } else {
                                  newArr = [];
                                  setBulkAssignId([]);
                                  for (
                                    let i = 0;
                                    i < allLeadsCheck.length;
                                    i++
                                  ) {
                                    allLeadsCheck[i].checked = false;
                                  }
                                }
                              }}
                            />
                          </th>
                        )}
                        <th>ID</th>
                        <th>SOURCE</th>
                        <th>Assigned To</th>
                        <th>CLIENT</th>
                        <th>CLIENT INFO</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredUsers.map((user) => (
                        <tr key={user.id}>
                          {userType.userType === "1" && (
                            <td className="p-4">
                              <input
                                type="checkbox"
                                className="selectLead"
                                onChange={(e) => {
                                  let newArray = [...bulkAssignId];
                                  if (e.target.checked) {
                                    newArray.push(user.id);
                                    setBulkAssignId(newArray);
                                  } else {
                                    newArray = [...bulkAssignId];
                                    newArray = newArray.filter(
                                      (data) => data !== user.id
                                    );
                                    setBulkAssignId(newArray);
                                  }
                                }}
                              />
                            </td>
                          )}
                          <td>
                            <p style={{ marginBottom: "6px" }}>
                              LD-<b>{user.id}</b>
                            </p>
                          </td>
                          <td>
                            <p className="mb-0">
                              Source: <b>{user.leadSource}</b>
                              <br />
                              Status:{" "}
                              <b>
                                {user.masterStatus?.charAt(0).toUpperCase() +
                                  user.masterStatus?.slice(1)}
                              </b>
                              <br />
                              Stage:{" "}
                              <b>
                                {user.status?.charAt(0).toUpperCase() +
                                  user.status?.slice(1)}
                              </b>
                              <br />
                              Pipeline:{" "}
                              <b>
                                {capitalizeFirstLetterOfEachWord(
                                  user.leadPipeline
                                )}
                              </b>
                              <br />
                              Company ID: <b>{user.companyId}</b>
                              <br />
                              KYC: <b>{user.isKycDone}</b>
                              <br />
                              Remark: <b>{user.remark}</b>
                              <br />
                              Added On:{" "}
                              <b>
                                {new Date(user.createdAt)
                                  .toISOString()
                                  .slice(0, 10)}
                              </b>
                              <br />
                            </p>
                          </td>
                          <td>
                            <div className="mb-0">
                              {user.assignedTo && <p>{user.assignedTo}</p>}
                            </div>
                          </td>
                          <td>
                            <p className="mb-0">
                              {user.primaryContactName
                                ?.charAt(0)
                                .toUpperCase() +
                                user.primaryContactName?.slice(1)}
                              <br />
                              Email: <b>{user.emailId}</b>
                              <br />
                              Phone: <b>{user.mobileNumber}</b>
                            </p>
                          </td>
                          <td>
                            <p className="mb-0">
                              Company:{" "}
                              <b>
                                {capitalizeFirstLetterOfEachWord(
                                  user.companyName
                                )}
                              </b>
                              <br />
                              Designation:{" "}
                              <b>
                                {capitalizeFirstLetterOfEachWord(
                                  user.primaryContactDesignation
                                )}
                              </b>
                              <br />
                              Business Category: <b>{user.businessCategory}</b>
                              <br />
                              Current Shipping Partner:{" "}
                              <b>{user.currentShippingPartner}</b>
                              <br />
                              Shipment Potential:{" "}
                              <b>{user.shipmentPotential}</b>
                              <br />
                              Min Expected Shipment to Fship:{" "}
                              <b>{user.minimumExpectedShipmentToFship}</b>
                              <br />
                              Client Persona: <b>{user.clientPersona}</b>
                              <br />
                              UTM:{" "}
                              <b>
                                {user.utmSource}-{user.utmMedium}-
                                {user.utmCampaign}-{user.utmTerm}-
                                {user.utmContent}
                              </b>
                            </p>
                          </td>
                          <td>
                            <a
                              className="btn ripple btn-warning btn-xs w-100 mb-1 mt-1"
                              onClick={() => loadcontent(user.id)}
                              style={{ color: "#fff" }}
                            >
                              Activity
                            </a>
                            <br />
                            <a
                              className="btn ripple btn-danger btn-xs w-100 mb-1 mt-1"
                              onClick={() =>
                                navigate(`/lead/contacts/${user.id}`)
                              }
                              style={{ color: "#fff" }}
                            >
                              Contacts
                            </a>
                            <br />
                            <button
                              type="button"
                              className="btn ripple btn-success btn-xs w-100 mb-1"
                              onClick={handleSetEditLead}
                              data-lead={JSON.stringify(user)}
                            >
                              Edit Lead
                            </button>
                            <br />
                            <button
                              className="btn ripple btn-info btn-xs w-100 mb-1"
                              onClick={() => {
                                setModalOpen(true);
                                setAssignedLeadId(user.id);
                              }}
                              style={{ color: "#fff" }}
                            >
                              {window.location.href.includes("NewLeads") ||
                              window.location.href.includes("leads/Open")
                                ? "Assign"
                                : "Open & ReAssign"}
                            </button>
                            <br />
                            {(window.location.href.includes("NewLeads") ||
                              window.location.href.includes("leads/Open")) && (
                              <button
                                className="btn btn-warning btn-xs w-100 mb-1"
                                onClick={() => {
                                  setStatusModalOpen(true);
                                  setNewStatusLeadId(user.id);
                                  setStageStatus(user?.status.toUpperCase());
                                }}
                                style={{ color: "#fff" }}
                              >
                                Stage
                              </button>
                            )}
                            <br />
                            <button
                              type="button"
                              className="btn ripple btn-secondary btn-xs w-100 mb-1"
                              onClick={handleLeadStatus}
                              data-lead={JSON.stringify(user)}
                            >
                              Lead Life Cycle
                            </button>
                            <br />
                            {user.masterStatus == "Won" && !user.isCustomer && (
                              <button
                                type="button"
                                className="btn ripple btn-primary btn-xs w-100 mb-1"
                                onClick={(e) => {
                                  handleWonLead(e);
                                  setLeadId(user.id);
                                }}
                                style={{ color: "#fff" }}
                              >
                                Assign to KAM
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {filteredUsers.length === 0 && !loader ? (
                    <div className="text-center row m-5">
                      <h1>No Leads Found</h1>
                    </div>
                  ) : null}
                </div>

                <div
                  className={`modal bg-black-7 ${
                    statusModalOPen ? "show" : ""
                  } `}
                  style={{
                    display: statusModalOPen ? "block" : "none",
                  }}
                  tabIndex="-1"
                  role="dialog"
                >
                  <div
                    className="modal-dialog modal-dialog-centered modal-xl"
                    role="document"
                  >
                    <div className="modal-content rounded-3">
                      <div className="modal-header">
                        <h5 className="modal-title">
                          Lead Stage : {stageStatus}
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={handleCloseStatusModal}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>

                      <div className="modal-body" style={{ width: "100%" }}>
                        <div className="row row-sm-1">
                          <div className="col-sm-4 form-group">
                            <label className="form-label">Set Stage : </label>
                            <select
                              name="newStatus"
                              value={newStatus}
                              className="form-control form-select select2"
                              onChange={(e) => {
                                setNewStatus(e.target.value);
                              }}
                            >
                              <option>Select</option>
                              {leadStatusOptions.map((data, index) => {
                                return (
                                  <option key={index} value={data.name}>
                                    {data.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="col-lg-12">
                            <button
                              className="btn ripple btn-primary"
                              type="button"
                              onClick={updateStatus}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <h1 className="text-center">Loading...</h1>
            )}

            <div
              style={{
                paddingBottom: "20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex" }}>
                <Select
                  defaultValue="20"
                  onChange={(value) => handlePageSize(value)}
                  style={{
                    marginTop: "10px",
                    height: "20px",
                    marginRight: "10px",
                  }}
                >
                  <Option value="20">20</Option>
                  <Option value="50">50</Option>
                  <Option value="100">100</Option>
                  <Option value="200">200</Option>
                  <Option value="500">500</Option>
                </Select>
                <span
                  style={{
                    marginTop: "16px",
                    color: "#808080",
                  }}
                >
                  Page Size
                </span>
              </div>
              <Stack spacing={2} style={{ marginTop: "20px" }}>
                <Pagination
                  count={isNaN(totalPages) ? 0 : totalPages}
                  page={currentPage}
                  variant="outlined"
                  onChange={handlePageChange}
                  color="primary"
                />
              </Stack>{" "}
            </div>

            <div
              className={`modal bg-black-7 ${
                modelOPen ? "show" : ""
              }  bg-black-7`}
              style={{ display: modelOPen ? "block" : "none" }}
              tabIndex="-1"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-xl"
                role="document"
              >
                <div className="modal-content rounded-3">
                  <div className="modal-header">
                    <h5 className="modal-title">Assign</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleCloseModal}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body" style={{ width: "100%" }}>
                    <div className="row row-sm-1">
                      <div className="col-sm-4 form-group">
                        <label className="form-label">Assign : </label>
                        <select
                          name="assignedTo"
                          value={assignedTo}
                          className="form-control form-select select2"
                          onChange={(e) => {
                            setAssignedTo(e.target.value);
                          }}
                        >
                          <option>Select</option>
                          {employees.map((data, index) => {
                            return (
                              <option value={data.id} key={index}>
                                {data.firstName + " " + data.lastName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-lg-12">
                        <button
                          className="btn ripple btn-primary"
                          type="button"
                          onClick={handleAssign}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`modal ${modelOPen2 ? "show" : ""}  bg-black-7`}
              style={{ display: modelOPen2 ? "block" : "none" }}
              tabIndex="-1"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-xl"
                role="document"
              >
                <div className="modal-content rounded-3">
                  <div className="modal-header">
                    <h5 className="modal-title">Bulk Lead Source</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleCloseModal2}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body" style={{ width: "100%" }}>
                    <div className="row row-sm-1">
                      <div className="col-sm-4 form-group">
                        <label className="form-label">Assign : </label>
                        <select
                          name="sourceTo"
                          value={sourceTo}
                          className="form-control form-select select2"
                          onChange={(e) => {
                            setSourceTo(e.target.value);
                          }}
                        >
                          <option value={""}>By Lead Source</option>
                          {leadSourceOptions.map((data, index) => {
                            return (
                              <option key={index} value={data.name}>
                                {data.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-lg-12">
                        <button
                          className="btn ripple btn-primary"
                          type="button"
                          onClick={handleAssign2}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`modal ${modelOPen3 ? "show" : ""} bg-black-7`}
              style={{ display: modelOPen3 ? "block" : "none" }}
              tabIndex="-1"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-xl"
                role="document"
              >
                <div className="modal-content rounded-3">
                  <div className="modal-header">
                    <h5 className="modal-title">Bulk Stage</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleCloseModal3}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body" style={{ width: "100%" }}>
                    <div className="row row-sm-1">
                      <div className="col-sm-4 form-group">
                        <label className="form-label">Assign : </label>
                        <select
                          name="stageTo"
                          value={stageTo}
                          className="form-control form-select select2"
                          onChange={(e) => {
                            setStageTo(e.target.value);
                          }}
                        >
                          <option>Select</option>
                          {leadStatusOptions.map((data, index) => {
                            return (
                              <option key={index} value={data.name}>
                                {data.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-lg-12">
                        <button
                          className="btn ripple btn-primary"
                          type="button"
                          onClick={handleAssign3}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`modal ${modelOPen4 ? "show" : ""}  bg-black-7`}
              style={{ display: modelOPen4 ? "block" : "none" }}
              tabIndex="-1"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-xl"
                role="document"
              >
                <div className="modal-content rounded-3">
                  <div className="modal-header">
                    <h5 className="modal-title">Bulk PipeLine</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleCloseModal4}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body" style={{ width: "100%" }}>
                    <div className="row row-sm-1">
                      <div className="col-sm-4 form-group">
                        <label className="form-label">Assign : </label>
                        <select
                          name="leadPipelineTo"
                          value={leadPipelineTo}
                          className="form-control form-select select2"
                          onChange={(e) => {
                            setPipelineTo(e.target.value);
                          }}
                        >
                          <option value={""}>By Lead Pipeline</option>
                          {leadPipelineOptions.map((data, index) => {
                            return (
                              <option key={index} value={data.name}>
                                {data.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-lg-12">
                        <button
                          className="btn ripple btn-primary"
                          type="button"
                          onClick={handleAssign4}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {}
          </div>
          <div className="main-footer text-center">
            <div className="container">
              <div className="">
                <div className="col-md-12">
                  <span>
                    Copyright © 2024 <a href="">FSHIP</a>. Developed by{" "}
                    <a href="http://fship.in/">FSHIP</a> All rights reserved.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewLeadsTable;
