import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import { useLocation } from "react-router-dom";
import Prince from "../../Components/Prince";
import TopHeader from "../../Components/TopHeader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { DateRangePicker } from "react-date-range";
import Loader from "../../assets/img/loader.gif";
import spiner from "../../assets/img/spiner.gif";
import "./index2.css";
import { useParams } from "react-router-dom";

import { DataContext } from "../../context/DataContext";
import { MdDateRange } from "react-icons/md";
import ReactSelect from "react-select";
import makeAnimated from "react-select/animated";
import SelectComponent from "../Sales CRM/SelectComponent";

import TabTableComponent from "./TabTableComponent";
// import "./DepartmentSelect.css";

const animatedComponents = makeAnimated();

function Cases() {
  const location = useLocation();
  const { pending } = location.state || {};

  // console.log(pending);

  const [currentPage, setCurrentPage] = useState(1);

  const [statusList, setStatusList] = useState([]);
  const [awbList, setAwbList] = useState([]);
  const [orderIdList, setOrderIdList] = useState([]);
  const [caseId, setCaseId] = useState("");

  const customerId = useParams();
  const result = useContext(DataContext);

  const [caseStatus, setCaseStatus] = useState("");
  const [caseSubType, setCaseSubType] = useState([]);
  const [caseList, setCaseList] = useState([]);
  // const [statusType, setStatusType] = useState([]);
  const [modelOPenAssign, setModalOpenAssign] = useState(false);
  const [modelOpenStatusChange, setModalOpenStatusChange] = useState(false);
  const [leadSourceOptions, setLeadSourceOptions] = useState([]);
  //const [customerData, setCustomerData] = useState([]);
  const [employee, setEmployee] = useState({});
  // const [filteredLeads, setFilteredLeads] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  // const [loaded, setLoaded] = useState(false);
  const [modelOPen, setModalOpen] = useState(false);
  const [modelOpenAwb, setModalOpenAwb] = useState(false);
  const [modelOpenOrderId, setModalOpenOrderId] = useState(false);
  const [applyFilter, setApplyFilter] = useState(false);
  const [modelOPen2, setModalOpen2] = useState(false);
  const [modelOPen3, setModalOpen3] = useState(false);
  const [modelOPen4, setModalOpen4] = useState(false);
  const [modelOPen5, setModalOpen5] = useState(false);
  const [statusModalOPen, setStatusModalOpen] = useState(false);
  const [dateFilterModalOPen, setDateFilterModalOpen] = useState(false);
  const [userType, setUserType] = useState({});
  const [employees, setEmployees] = useState([]);
  const [newStatus, setNewStatus] = useState("");
  const [newStatusLeadId, setNewStatusLeadId] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [sourceTo, setSourceTo] = useState("");
  const [stageTo, setStageTo] = useState("");
  const [leadPipelineTo, setPipelineTo] = useState("");

  const [bulkAssignId, setBulkAssignId] = useState([]);

  const [assignedLeadId, setAssignedLeadId] = useState("");
  const { status } = useParams();
  const [loader, setLoader] = useState(false);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  // const [isModalOpen4, setIsModalOpen4] = useState(false);

  const initialFormData = {
    assignEmployee: [""],
  };
  // const [disabledTabs, setDisabledTabs] = useState({});
  const [formData, setFormData] = useState(initialFormData);
  const [countWon, setCountWon] = useState(0);

  // const [countnegotiation, setCountnegotiation] = useState(0);
  // const [countLOST, setCountLOST] = useState(0);
  // const [countconatactInFuture, setCountconatactInFuture] = useState(0);
  // const [countCallAttempted, setCountCallAttempted] = useState(0);
  // const [countproposal, setCountproposal] = useState(0);
  // const [countFollowup, setCountFollowup] = useState(0);
  // const [countNew, setCountNew] = useState(0);
  // const [countOpen, setCountOpen] = useState(0);
  // const [totalCount, setTotalCount] = useState(0);
  const [stageValue, setStageValue] = useState("");
  // const selectAllLead = document.getElementById("bulkSelect");
  const [leadStatusOptions, setLeadStatusOptions] = useState([]);
  // const [filterByGenericEmailPattern, setFilterByGenericEmailPattern] =
  //   useState("true");
  const [filterByObj, setFilterByObj] = useState({
    caseSubType: "",
    caseType: "",
    customerId: "",
    to: "",
    from: "",
    kamId: "",
    search: "",
  });

  const boxStyle = {
    width: "400px", // Set the desired width
    height: "25px",
    padding: "2px", // Optional padding
    border: "1px solid #ccc", // Optional border
    overflow: "auto", // Enables scroll if content exceeds the box
    boxSizing: "border-box", // Ensures padding is included in the width and height
    backgroundColor: "#fff", // Optional background color
    borderRadius: "2px",
  };
  //  const  style={{
  //   pointerEvents: isDisabled ? 'none' : 'auto', // Disable click events when disabled
  //   opacity: isDisabled ? 0.5 : 1,              // Change opacity to indicate disabled state
  //   cursor: isDisabled ? 'not-allowed' : 'pointer', // Show not-allowed cursor when disabled
  //   textDecoration: 'none',
  //   color: isDisabled ? '#ccc' : '#007bff', // Change color to indicate disabled state
  // }}
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#f0f0f0" : "#fff", // Background color of the control
      borderColor: state.isFocused ? "#2684FF" : "#ccc", // Border color when focused or not
      boxShadow: state.isFocused ? "0 0 0 1px #2684FF" : null, // Box shadow when focused
      "&:hover": {
        borderColor: "#2684FF", // Border color on hover
      },
      padding: "0px",
      // Padding inside the control
    }),
    menu: (provided) => ({
      ...provided,
      width: "100%",
      backgroundColor: "#fff", // Background color of the dropdown menu
      // Rounded corners for the dropdown menu
      zIndex: 999, // Ensure it appears above other elements
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#2684FF"
        : state.isFocused
        ? "#f0f0f0"
        : "#fff", // Background color for selected and focused options
      color: state.isSelected ? "#fff" : "#333", // Text color
      padding: "5px", // Padding inside each option
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#333", // Color of the selected value
      fontWeight: "bold", // Bold text for selected value
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#999", // Color of the placeholder text
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "#2684FF" : "#ccc", // Color of the dropdown indicator (arrow)
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "#2684FF" : "#ccc", // Color of the clear indicator (X)
    }),
  };
  // console.log(totalPages);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [linkActive, setLinkActive] = useState("In Progress");
  const [linkActive1, setLinkActive1] = useState("In Progress");
  const [options, setOptions] = useState([]);
  const [employeeDepartment, setEmployeeDepartment] = useState([]);
  const [department, setDepartment] = useState([]);

  //const [filterByLeadSource, setFilterByLeadSource] = useState([]);
  const [filterByLeadSource1, setFilterByLeadSource1] = useState([]);
  const [filterByLeadSource2, setFilterByLeadSource2] = useState([]);
  const [filterByLeadSource3, setFilterByLeadSource3] = useState([]);
  const [filterByLeadSource4, setFilterByLeadSource4] = useState([]);
  const [selectedItems, setSelectedItems] = useState("");
  const [selectDepartment, setSelectDepartment] = useState("");
  const [filterByEmployeeIdKam1, setFilterByEmployeeIdKam1] = useState("");
  const [filterByEmployeeIdKam2, setFilterByEmployeeIdKam2] = useState("");
  const [filterByEmployeeIdKam3, setFilterByEmployeeIdKam3] = useState("");
  // const [showDropdown, setShowDropdown] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [pageSize, setPageSize] = useState(20);

  const dropdownRef = useRef(null);

  const dropdownRef6 = useRef(null);

  const departmentOptions = department.map((dept) => ({
    label: dept.name,
    value: dept.id,
  }));
  const employeeOptions = employeeDepartment.map((dept) => ({
    label: dept.name,
    value: dept.id,
  }));

  const closeDropdown = (e) => {
    if (dropdownRef6.current && !dropdownRef6.current.contains(e.target)) {
      setDropdownVisible(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", closeDropdown);
    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, []);
  // function containsPercentage(str) {
  //   return str.includes(" ");
  // }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // const handlePageChange = (event, newPage) => {
  //   setCurrentPage(newPage);
  //   scrollToTop();
  // };
  // const handlePageSize = (value) => {
  //   setPageSize(value.target.innerText);

  //   scrollToTop();
  // };

  const handleEmployeeAssign = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${apiUrl}/caseM/assignEmployee?caseId=${assignedEmployeeId}&empIds=${selectedItems}
      `,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
        }
      );

      const data = await response.json();

      if (data.status === "success") {
        setSuccessMessage(`Case ${assignedEmployeeId} assigned successfully.`);
        toast.success(data.message);

        setModalOpenAssign(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setErrorMessage(`Failed to assign case ${assignedEmployeeId}.`);
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error assigning case:", error.message);
      setErrorMessage(
        `An error occurred while assigning case ${assignedEmployeeId}.`
      );
    } finally {
      setAssignedEmployeeId(null);
      setSelectedItems([]);
    }
  };

  useEffect(() => {
    const Token = localStorage.getItem("Token");

    fetch(`${apiUrl}/caseMaster/getCaseType`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            setCaseList(data.data);
          } else {
            console.error("API response does not contain users array:", data);
          }
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    const Token = localStorage.getItem("Token");

    fetch(`${apiUrl}/caseMaster/getAllCaseSubType`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            setCaseSubType(data.data);
          } else {
            console.error("API response does not contain users array:", data);
          }
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  // useEffect(() => {
  //   const Token = localStorage.getItem("Token");

  //   fetch(`${apiUrl}/caseM/customerDropdown`, {
  //     headers: {
  //       Authorization: `Bearer ${Token}`,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.status === "success") {
  //         if (Array.isArray(data.data)) {
  //           setCustomerOptions(data.data);
  //         } else {
  //           console.error("API response does not contain users array:", data);
  //         }
  //       } else {
  //         console.error("API request was not successful:", data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // }, []);

  const handleCheckboxChange1 = useCallback(
    (selected) => {
      setFilterByLeadSource1(
        selected ? selected.map((option) => option.value) : []
      );
      setFilterByEmployeeIdKam1(
        selected ? selected.map((option) => option.id) : []
      );
    },
    [filterByEmployeeIdKam1]
  );

  const handleCheckboxChange3 = useCallback(
    (selected) => {
      setFilterByLeadSource3(
        selected ? selected.map((option) => option.value) : []
      );
      setFilterByEmployeeIdKam3(
        selected ? selected.map((option) => option.id) : []
      );
    },
    [filterByEmployeeIdKam3]
  );

  const handleCheckboxChange2 = useCallback(
    (selected) => {
      setFilterByLeadSource2(
        selected ? selected.map((option) => option.value) : []
      );
      setFilterByEmployeeIdKam2(
        selected ? selected.map((option) => option.id) : []
      );
    },
    [filterByEmployeeIdKam2]
  );

  useEffect(() => {
    // Fetch countries and populate the countryOptions
    fetch(`${apiUrl}/employee/allEmpDesig`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.data && Array.isArray(data.data)) {
          setOptions(data.data);
        } else {
          console.error(
            "API response is not in the expected format for countries."
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching country data:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/employee/allEmpDesig?department=${selectDepartment}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.data && Array.isArray(data.data)) {
          setEmployeeDepartment(data.data);
        } else {
          console.error(
            "API response is not in the expected format for countries."
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching country data:", error);
      });
  }, [selectDepartment]);

  // useEffect(() => {
  //   const Token = localStorage.getItem("Token");

  //   fetch(`${apiUrl}/caseM/customerDropdown`, {
  //     headers: {
  //       Authorization: `Bearer ${Token}`,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.status === "success") {
  //         if (Array.isArray(data.data)) {
  //           setStatusType(data.data);
  //         } else {
  //           console.error("API response does not contain users array:", data);
  //         }
  //       } else {
  //         console.error("API request was not successful:", data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // }, []);

  useEffect(() => {
    setUserType(result.employeeData);
  }, []);

  useEffect(() => {
    setEmployee(result.employeeData);
  }, []);

  const handleDateFilterClick = () => {
    setDateFilterModalOpen(true);
  };

  const [leadPipelineOptions, setLeadPipelineOptions] = useState([]);

  const [statusMaster, setStatusMaster] = useState([]);
  const [from, setFrom] = useState("");
  const [assignedEmployeeId, setAssignedEmployeeId] = useState("");
  // const [filterByGenericEmailPattern, setFilterByGenericEmailPattern] = useState('')

  const apiUrl = process.env.REACT_APP_URL;
  // console.log(apiUrl);

  const Token = localStorage.getItem("Token");
  // console.log("Token:", Token);

  const navigate = useNavigate();

  // const loadcontent = (id) => {
  //   navigate(`/case-detail/${id}`);
  // };
  // const splitStringIntoLines = (str, maxLength) => {
  //   const lines = [];
  //   let currentLine = "";

  //   str.split(" ").forEach((word) => {
  //     if ((currentLine + word).length <= maxLength) {
  //       currentLine += (currentLine ? " " : "") + word;
  //     } else {
  //       lines.push(currentLine);
  //       currentLine = word;
  //     }
  //   });

  //   if (currentLine) {
  //     lines.push(currentLine);
  //   }

  //   return lines;
  // };

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/35`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          // setLeadSourceOptions(data.data);
          setStatusMaster(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/7`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          // setLeadSourceOptions(data.data);
          setDepartment(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  const handleAwbNoList = () => {
    // setModalOpenAwb(true);

    fetch(`${apiUrl}/caseM/awbList?caseId=${caseId}`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setAwbList(data.data);
        } else {
          console.error("API response does not contain an array:", data);
          // Optionally: Provide user feedback on UI about the issue
        }
      })
      .catch((error) => {
        console.error("Error fetching AWB list:", error);
      });
  };
  const handleOrderIdList = () => {
    // setModalOpenAwb(true);

    fetch(`${apiUrl}/caseM/ordeIdList?caseId=${caseId}`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setOrderIdList(data.data);
        } else {
          console.error("API response does not contain an array:", data);
          // Optionally: Provide user feedback on UI about the issue
        }
        // Close modal or loading indicator
      })
      .catch((error) => {
        console.error("Error fetching AWB list:", error);
      });
  };

  useEffect(() => {
    const Token = localStorage.getItem("Token");

    fetch(`${apiUrl}/customer/kamDropDown`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            setStatusList(data.data);
          } else {
            console.error("API response does not contain users array:", data);
          }
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    handleAwbNoList();
  }, [caseId]);

  useEffect(() => {
    handleOrderIdList();
  }, [caseId]);

  // KYC
  const handleDateChange1 = (ranges) => {
    // Adjust the start date to include the entire day
    const adjustedStartDate = new Date(ranges.selection.startDate);
    adjustedStartDate.setHours(23, 59, 59, 999);

    // Adjust the end date to exclude the entire day by subtracting one millisecond
    const adjustedEndDate = new Date(ranges.selection.endDate);
    adjustedEndDate.setHours(23, 59, 59, 999);

    setToDate(adjustedEndDate.toISOString());
    setFromDate(adjustedStartDate.toISOString());

    setFilterByObj({
      ...filterByObj,
      to: adjustedEndDate.toISOString(),
      from: adjustedStartDate.toISOString(),
    });

    setDate(ranges.selection);
  };

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/2`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setLeadPipelineOptions(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  const updateStatus = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("status", newStatus);
    const response = await fetch(
      `${apiUrl}/lead/changeStatus/${newStatusLeadId}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      }
    );
    // console.log(response, "statuss=======>>>>>>>");

    if (!response.ok) {
      toast.error(response.message);
    }
    setModalOpen(false);

    const data = await response.json();
    toast.success("Status Successfully Updated");

    // console.log("Form submitted successfully!", data);
  };

  // Bulk Assign
  const handleAssign = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("empId", assignedTo);
    if (bulkAssignId.length) {
      formDataToSend.append("leadsId", JSON.stringify(bulkAssignId));
      const response = await fetch(`${apiUrl}/lead/assignManyLead`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });
      // console.log(response, "hereeeee=======>>>>>>>");

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setModalOpen(false);

      const data = await response.json();
      toast.success("Assigned successfully");
      // console.log("Form submitted successfully!", data);
      setTimeout(() => {
        window.location.reload();
      }, 2000);

      return;
    }

    const response = await fetch(
      `${apiUrl}/lead/assignedLead/${assignedLeadId}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      }
    );
    // console.log(response, "hereeeee=======>>>>>>>");

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    setModalOpen(false);

    const data = await response.json();
    toast.success("Assigned successfully");
    // console.log("Form submitted successfully!", data);

    // window.location.reload();
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };
  //Status CHnage
  const handleStatusChange = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/caseM/changeStatus?caseId=${caseId}&status=${caseStatus}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${Token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        toast.success("Status updated successfully");
        setModalOpenStatusChange(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(`Error updating status: ${data.message}`);
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };
  // Bulk Source

  const handleAssign2 = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("leadSource", sourceTo);
    // console.log(bulkAssignId);
    if (bulkAssignId.length) {
      formDataToSend.append("leadArray", JSON.stringify(bulkAssignId));
      const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });
      // console.log(response, "hereeeee=======>>>>>>>");

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setModalOpen2(false);

      const data = await response.json();
      toast.success("Assigned successfully");
      // console.log("Form submitted successfully!", data);
      window.location.reload();
      return;
    }

    const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Token}`,
      },
      body: formDataToSend,
    });
    // console.log(response, "hereeeee=======>>>>>>>");

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    setModalOpen2(false);

    const data = await response.json();
    toast.success("Assigned successfully");

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  // Bulk Stage
  const handleAssign3 = async () => {
    const formDataToSend = new FormData();

    if (true) {
      const response = await fetch(
        `${apiUrl}/caseM/caseStatus?caseIds=${bulkAssignId}&&status=${stageTo}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${Token}`,
          },
          // body: formDataToSend,
        }
      );
      //console.log(response, "hereeeee=======>>>>>>>");

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setModalOpen3(false);

      const data = await response.json();
      toast.success("Status Changes successfully");
      //console.log("Form submitted successfully!", data);
      // window.location.reload();
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      return;
    }

    // const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
    //   method: "POST",
    //   headers: {
    //     Authorization: `Bearer ${Token}`,
    //   },
    //   body: formDataToSend,
    // });
    // //console.log(response, "hereeeee=======>>>>>>>");

    // if (!response.ok) {
    //   throw new Error(`HTTP error! Status: ${response.status}`);
    // }
    // setModalOpen3(false);

    // const data = await response.json();
    // toast.success("Assigned successfully");
    // // console.log("Form submitted successfully!", data);
    // window.location.reload();
  };

  const modalRef = useRef();

  const handleOpenModal = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
    document.body.classList.add("modal-open");

    const url =
      window.location.href.includes("cases") &&
      window.location.href.includes("%")
        ? `${apiUrl}/casem/exportCases?status=${
            // status === "All" ? "" : status
            // stageValue?.includes("Pending")
            //   ? stageValue
            //   : status === "All"
            //   ? ""
            //   : status
            stageValue?.includes("Pending")
              ? stageValue
              : status === "All"
              ? ""
              : pending?.includes("Pending")
              ? pending
              : status
          }&caseType=${filterByObj.caseType}&caseSubType=${
            filterByObj.caseSubType
          }&customerId=${
            isNumber(customerId.status)
              ? customerId.status
              : filterByObj.customerId
          }&kamId=${filterByObj.kamId}&to=${filterByObj.to?.substring(
            0,
            10
          )}&from=${filterByObj.from?.substring(0, 10)}&search=${
            filterByObj.search
          }&page=${currentPage}&pageSize=${pageSize}`
        : `${apiUrl}/casem/exportCases?status=${
            isNumber(status) ? "" : status == "All" ? "" : status
          }&caseType=${filterByObj.caseType}&caseSubType=${
            filterByObj.caseSubType
          }&customerId=${
            isNumber(customerId.status)
              ? customerId.status
              : filterByObj.customerId
          }&kamId=${filterByObj.kamId}&to=${filterByObj.to?.substring(
            0,
            10
          )}&from=${filterByObj.from?.substring(0, 10)}&search=${
            filterByObj.search
          }&page=${currentPage}&pageSize=${pageSize}`;
    // IMPORT
    fetch(url, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          // console.log(data.data.fileURL);
          setFrom(data.data.fileURL);
        }
      });
  };

  const handleConfirm = () => {
    window.location.href = from;

    setIsModalOpen(false);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
  const handleAssign5 = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${apiUrl}/caseM/assignEmployee?caseId=${bulkAssignId}&empIds=${selectedItems}
      `,
        {
          method: "POST", // Change the method to POST
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
        }
      );

      const data = await response.json();

      if (data.status === "success") {
        setSuccessMessage(`Case ${assignedEmployeeId} assigned successfully.`);
        toast.success(data.message);
        setModalOpen5(false);
        // navigate("/cases/All");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setErrorMessage(`Failed to assign case ${assignedEmployeeId}.`);
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error assigning case:", error.message);
      setErrorMessage(
        `An error occurred while assigning case ${assignedEmployeeId}.`
      );
    } finally {
      // Reset state
      setAssignedEmployeeId(null);
      setSelectedItems([]);
    }

    // Add your logic for handling the assignment
  };

  // bulk pipeline
  const handleAssign4 = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("leadPipeline", leadPipelineTo);
    if (bulkAssignId.length) {
      formDataToSend.append("leadArray", JSON.stringify(bulkAssignId));
      const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });
      //  console.log(response, "hereeeee=======>>>>>>>");

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setModalOpen4(false);

      const data = await response.json();
      toast.success("Assigned successfully");
      //  console.log("Form submitted successfully!", data);
      window.location.reload();
      return;
    }

    const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Token}`,
      },
      body: formDataToSend,
    });
    // console.log(response, "hereeeee=======>>>>>>>");

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    setModalOpen4(false);

    const data = await response.json();
    toast.success("Assigned successfully");
    // console.log("Form submitted successfully!", data);
    window.location.reload();
  };

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/8`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setLeadStatusOptions(data.data);
          //  console.log(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    setFilterByObj({
      ...filterByObj,
      caseType: filterByEmployeeIdKam1,
    });
  }, [filterByEmployeeIdKam1]);
  useEffect(() => {
    setFilterByObj({
      ...filterByObj,
      customerId: filterByLeadSource4,
    });
  }, [filterByLeadSource4]);
  useEffect(() => {
    setFilterByObj({
      ...filterByObj,
      caseSubType: filterByEmployeeIdKam2,
    });
  }, [filterByEmployeeIdKam2]);
  useEffect(() => {
    setFilterByObj({
      ...filterByObj,
      kamId: filterByEmployeeIdKam3,
    });
  }, [filterByEmployeeIdKam3]);

  const handleCloseModal = () => {
    setModalOpen(false);
    setIsModalOpen(false);
    window.location.reload();
  };
  const handleCloseModalAwb = () => {
    setModalOpenAwb(false);
  };
  const handleCloseModalOrderId = () => {
    setModalOpenOrderId(false);
  };
  const handleCloseModalStatus = () => {
    setModalOpenStatusChange(false);
  };
  const handleCloseModalAssign = () => {
    setModalOpenAssign(false);
    setIsModalOpen(false);
  };

  const handleCloseModal2 = () => {
    setModalOpen2(false);
    setModalOpen2(false);
  };

  const handleCloseModal3 = () => {
    setModalOpen3(false);
  };

  const handleCloseModal4 = () => {
    setModalOpen4(false);
    setModalOpen4(false);
  };
  const handleCloseModal5 = () => {
    setModalOpen5(false);
    setModalOpen5(false);
  };

  const handleCloseStatusModal = () => {
    setStatusModalOpen(false);
  };

  const handleCloseDateFilterModal = () => {
    setDateFilterModalOpen(false);
  };

  const handleCheckboxChangeAssign = (option) => {
    const selectedLabels = option.map((options) => options.value);
    // console.log("Selected Labels:", option);
    setSelectedItems(selectedLabels);
  };

  const handleDepartmentAssign1 = (option) => {
    const selectedLabels = option.map((options) => options.label);
    // console.log("Selected Labels:", selectedLabels);
    setSelectDepartment(selectedLabels);
  };

  useEffect(() => {
    const Token = localStorage.getItem("Token");
    // console.log("Token:", Token);

    fetch(`${apiUrl}/employee/employees`, {
      headers: {
        Authorization: `Bearer ${Token}`, // Include the Token in the Authorization header
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            // console.log(data.data, "all employees");
            setEmployees(data.data);
          } else {
            console.error("API response does not contain users array:", data);
          }
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // const filteredUsers = filteredLeads.filter((user) => user);
  // const filteredAllCase = filteredLeads.filter(
  //   (user) => user.customer && user.customer.id == status
  // );

  // useEffect(() => {
  //   if (status !== undefined) {
  //     const statusId = filteredAllCase.length && filteredAllCase[0].customerId;
  //     // console.log(statusId);
  //   }
  // });

  const isNumber = (status) => !isNaN(status);
  // console.log(stageValue);
  // const idToNameMap = caseList.reduce((acc, option) => {
  //   acc[option.id] = option.name;
  //   return acc;
  // }, {});

  // Get the names corresponding to the selected IDs

  const handleApplyFilter = () => {
    setApplyFilter(!applyFilter);
  };
  const handleStageValue = (value) => {
    setStageValue(value);
  };
  //console.log(filterByObj);
  return (
    <div className="page">
      <TopHeader />
      <Prince />

      {/* <Support data={apiData} /> */}
      <div className="main-content side-content pt-0 m-0">
        <div className="main-container container-fluid">
          <div className="inner-body">
            <div className="page-header">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">Case List</h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a>Cases </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    New Cases List
                  </li>
                </ol>
              </div>
            </div>
            <div style={{ marginTop: "30px" }}>
              <div className="row">
                <div className="card custom-card">
                  <div className="card-body py-3">
                    <div className="row">
                      <div className="col-sm-4">
                        <label>Search Here</label>
                        <input
                          type="search"
                          className="form-control"
                          placeholder="Search..."
                          aria-controls="example1"
                          onChange={(e) => {
                            setSearch(e.target.value);

                            setFilterByObj({
                              ...filterByObj,
                              search: e.target.value,
                            });
                            // handleNavigation();
                          }}
                          style={{ border: "1px Solid #8E3E63" }}
                        />
                      </div>
                      <div className="col-sm-2 ">
                        <div
                          className="col-xs-3 "
                          // style={{ marginTop: "30px" }}
                        >
                          <label>Date Filter</label>
                          <button
                            className="calender btn btn-outline-danger"
                            onClick={handleDateFilterClick}
                            style={{
                              fontSize: "11px",
                              width: "215px",
                              // backgroundColor: "rgb(62, 72, 112)",
                              // border: "none",
                            }}
                          >
                            <MdDateRange
                              size={16}
                              style={{ display: "inline" }}
                            />{" "}
                            {`${format(
                              date.startDate,
                              "MMM dd yyyy"
                            )} -- ${format(date.endDate, "MMM dd yyyy")}`}
                          </button>
                          <div
                            className={`modal ${
                              dateFilterModalOPen ? "show" : ""
                            } bg-black-7`}
                            style={{
                              display: dateFilterModalOPen ? "block" : "none",
                            }}
                            tabIndex="-1"
                            role="dialog"
                          >
                            <div
                              className="modal-dialog modal-dialog modal-xl"
                              role="document"
                            >
                              <div
                                className="modal-content"
                                style={{ borderRadius: "8px" }}
                              >
                                <div className="modal-header">
                                  <h5 className="modal-title">
                                    Select Date Range
                                  </h5>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleCloseDateFilterModal}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div
                                  className="modal-body"
                                  style={{ width: "100%" }}
                                >
                                  <DateRangePicker
                                    ranges={[date]}
                                    onChange={handleDateChange1}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-2 ">
                        <SelectComponent
                          label={"Case Type"}
                          options={caseList}
                          handleSelectChange={handleCheckboxChange1}
                          selectedOptions={filterByLeadSource1}
                        ></SelectComponent>
                      </div>

                      <div className="col-sm-2 ">
                        <SelectComponent
                          label={"Case Subtype"}
                          options={caseSubType}
                          handleSelectChange={handleCheckboxChange2}
                          selectedOptions={filterByLeadSource2}
                        ></SelectComponent>
                      </div>

                      <div className="col-sm-2 ">
                        <SelectComponent
                          label={"KAM"}
                          options={statusList}
                          handleSelectChange={handleCheckboxChange3}
                          selectedOptions={filterByLeadSource3}
                        ></SelectComponent>
                      </div>

                      <div className="d-flex">
                        <div className="mr-2">
                          <button
                            style={{ marginTop: "30px" }}
                            className="btn btn-outline-warning"
                            onClick={handleApplyFilter}
                          >
                            Apply Filter
                          </button>
                        </div>
                        <div className="mx-2">
                          <button
                            style={{ marginTop: "30px" }}
                            className="btn btn-outline-warning"
                            onClick={() => {
                              setFilterByObj({
                                leadSource: "",
                                leadPipeline: "",
                                businessCategory: "",
                                shipmentPotential: "",
                                clientPersona: "",
                                partner: "",
                                to: "",
                                from: "",
                                isKycDone: "",
                                genericEmailPattern: "",
                                employeeId: "",
                                minimumExpectedShipmentToFship: "",
                              });
                              setDate({
                                startDate: new Date(),
                                endDate: new Date(),
                                key: "selection",
                              });
                              window.location.reload();
                            }}
                          >
                            Clear Filter
                          </button>
                        </div>
                        <div>
                          <button
                            style={{ marginTop: "30px" }}
                            className="btn btn-outline-warning"
                            onClick={handleOpenModal}
                          >
                            Export
                          </button>
                          <div
                            className={`modal ${
                              isModalOpen ? "show" : ""
                            } bg-black-7`}
                            style={{ display: isModalOpen ? "block" : "none" }}
                            tabIndex="-1"
                            role="dialog"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered modal-xl"
                              role="document"
                            >
                              <div className="modal-content rounded-3">
                                <div className="modal-header">
                                  <h5 className="modal-title">
                                    download excel
                                  </h5>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleCloseModal}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>

                                <div
                                  className="modal-body "
                                  style={{ width: "100%" }}
                                >
                                  {from !== "" ? (
                                    <p>Click here to download</p>
                                  ) : (
                                    <p>
                                      Please wait , Download button will appear
                                      soon...{" "}
                                    </p>
                                  )}
                                  {from !== "" ? (
                                    <button
                                      className="btn ripple btn-primary"
                                      type="button"
                                      onClick={handleConfirm}
                                    >
                                      Download
                                    </button>
                                  ) : (
                                    <img src={spiner} height={"50px"}></img>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {<></>}
                      {/* {loader ? (
                        <div className="loading-overlay">
                          <img src={Loader} alt="Loading..." />
                        </div>
                      ) : (
                        <></>
                      )} */}
                      <div
                        className={`modal ${
                          modelOPenAssign ? "show" : ""
                        } bg-black-7`}
                        style={{ display: modelOPenAssign ? "block" : "none" }}
                        tabIndex="-1"
                        role="dialog"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered modal-sm"
                          role="document"
                        >
                          <div className="modal-content rounded-3">
                            <div className="modal-header">
                              <h5 className="modal-title">Case Assign</h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleCloseModalAssign}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div
                              className="modal-body"
                              // style={{ width: "100%" }}
                            >
                              <div className="row row-sm-1">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Department
                                  </label>
                                  <ReactSelect
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    onChange={(option) => {
                                      handleDepartmentAssign1(option);
                                    }}
                                    styles={customStyles}
                                    isMulti
                                    options={departmentOptions}
                                  />
                                </div>
                                <div
                                  className="col-sm-4 form-group "
                                  style={{ minWidth: "100%" }}
                                  ref={dropdownRef}
                                >
                                  <label className="form-label">
                                    Employee :{" "}
                                  </label>

                                  <ReactSelect
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    // defaultValue={[
                                    //   colourOptions[4],
                                    //   colourOptions[5],
                                    // ]}
                                    onChange={(option) => {
                                      handleCheckboxChangeAssign(option);
                                    }}
                                    styles={customStyles}
                                    isMulti
                                    options={employeeOptions}
                                  />
                                </div>
                                <div className="col-lg-12">
                                  <button
                                    className="btn ripple btn-primary"
                                    type="button"
                                    onClick={(e) => handleEmployeeAssign(e)}
                                  >
                                    Assign
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
              {/* Row */}
            </div>
            <>
              <TabTableComponent
                handleStageValue={handleStageValue}
                modalOpen3={modelOPen3}
                setModalOpen3={setModalOpen3}
                modalOpen5={modelOPen5}
                setModalOpen5={setModalOpen5}
                bulkAssignId={bulkAssignId}
                setBulkAssignId={setBulkAssignId}
                filterByObj={filterByObj}
                applyFilter={applyFilter}
                assignedEmployeeId={assignedEmployeeId}
                setAssignedEmployeeId={setAssignedEmployeeId}
                caseId={caseId}
                setCaseId={setCaseId}
                modalOpenAwb={modelOpenAwb}
                setModalOpenAwb={setModalOpenAwb}
                modalOpenOrderId={modelOpenOrderId}
                setModalOpenOrderId={setModalOpenOrderId}
                statusModalOpen={statusModalOPen}
                setStatusModalOpen={setStatusModalOpen}
                modalOpenStatusChange={modelOpenStatusChange}
                setModalOpenStatusChange={setModalOpenStatusChange}
                newStatus={newStatus}
                setNewStatus={setNewStatus}
                leadStatusOptions={leadStatusOptions}
                updateStatus={updateStatus}
                handleCloseStatusModal={handleCloseStatusModal}
                setModalOpenAssign={setModalOpenAssign}
              />
            </>
            <div
              className={`modal ${modelOPen ? "show" : ""}`}
              style={{ display: modelOPen ? "block" : "none" }}
              tabIndex="-1"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-xl"
                role="document"
              >
                <div className="modal-content rounded-3">
                  <div className="modal-header">
                    <h5 className="modal-title">Assign</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleCloseModal}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body" style={{ width: "100%" }}>
                    <div className="row row-sm-1">
                      <div className="col-sm-4 form-group">
                        <label className="form-label">Assign : </label>
                        <select
                          name="assignedTo"
                          value={assignedTo}
                          className="form-control form-select select2"
                          onChange={(e) => {
                            setAssignedTo(e.target.value);
                          }}
                        >
                          <option>Select</option>
                          {employees.map((data, index) => {
                            return (
                              <option value={data.id} key={index}>
                                {data.firstName + " " + data.lastName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-lg-12">
                        <button
                          className="btn ripple btn-primary"
                          type="button"
                          onClick={handleAssign}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`modal ${modelOpenAwb ? "show" : ""} bg-black-7`}
              style={{
                display: modelOpenAwb ? "block" : "none",
                overflowY: "auto",
              }}
              tabIndex="-1"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-sm"
                role="document"
              >
                <div className="modal-content rounded-3">
                  <div className="modal-header">
                    <h5 className="modal-title">AWB Number List</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleCloseModalAwb}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div
                    className="modal-body"
                    style={{ maxHeight: "80vh", overflowY: "auto" }}
                  >
                    <div className="row">
                      <div className="col form-group">
                        <ul className="list-group">
                          {awbList.map((item, index) => (
                            <li
                              className="list-group-item list-group-item-primary"
                              key={index}
                            >
                              {item.awbNo}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`modal ${modelOpenOrderId ? "show" : ""} bg-black-7`}
              style={{
                display: modelOpenOrderId ? "block" : "none",
                overflowY: "auto",
              }}
              tabIndex="-1"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-sm"
                role="document"
              >
                <div className="modal-content rounded-3">
                  <div className="modal-header">
                    <h5 className="modal-title">Order Id List</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleCloseModalOrderId}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div
                    className="modal-body"
                    style={{ maxHeight: "80vh", overflowY: "auto" }}
                  >
                    <div className="row">
                      <div className="col form-group">
                        <ul className="list-group">
                          {orderIdList.map((item, index) => (
                            <li
                              className="list-group-item list-group-item-primary"
                              key={index}
                            >
                              {item.orderId}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`modal ${
                modelOpenStatusChange ? "show" : ""
              } bg-black-7`}
              style={{
                display: modelOpenStatusChange ? "block" : "none",
              }}
              tabIndex="-1"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-sm"
                role="document"
              >
                <div className="modal-content rounded-3">
                  <div className="modal-header">
                    <h5 className="modal-title">Change Status</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleCloseModalStatus}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div
                    className="modal-body"
                    // style={{ maxHeight: "80vh", overflowY: "auto" }}
                  >
                    <div className="row  row-sm-1">
                      {" "}
                      <div className="col-m-4 form-group">
                        <label className="form-label">Select Status : </label>
                        <select
                          name="newStatus"
                          value={caseStatus}
                          className="form-control form-select select2"
                          onChange={(e) => {
                            setCaseStatus(e.target.value);
                          }}
                        >
                          <option>Select</option>
                          {statusMaster.map((data, index) => {
                            return (
                              <option value={data.name} key={index}>
                                {data.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-lg-12">
                        <button
                          className="btn ripple btn-primary"
                          type="button"
                          onClick={handleStatusChange}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`modal ${modelOPen2 ? "show" : ""}`}
              style={{ display: modelOPen2 ? "block" : "none" }}
              tabIndex="-1"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-xl"
                role="document"
              >
                <div className="modal-content rounded-3">
                  <div className="modal-header">
                    <h5 className="modal-title">Bulk Lead Source</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleCloseModal2}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body" style={{ width: "100%" }}>
                    <div className="row row-sm-1">
                      <div className="col-sm-4 form-group">
                        <label className="form-label">Assign : </label>
                        <select
                          name="sourceTo"
                          value={sourceTo}
                          className="form-control form-select select2"
                          onChange={(e) => {
                            setSourceTo(e.target.value);
                          }}
                        >
                          <option>By Lead Source</option>
                          {leadSourceOptions.map((data, index) => {
                            return (
                              <option value={data.name} key={index}>
                                {data.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-lg-12">
                        <button
                          className="btn ripple btn-primary"
                          type="button"
                          onClick={handleAssign2}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`modal ${modelOPen3 ? "show" : ""} bg-black-7`}
              style={{ display: modelOPen3 ? "block" : "none" }}
              tabIndex="-1"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-sm"
                role="document"
              >
                <div className="modal-content rounded-3">
                  <div className="modal-header">
                    <h5 className="modal-title">Bulk Status Change</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleCloseModal3}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <div className="row row-sm-1">
                      <div
                        className="col-sm-4 form-group"
                        style={{ minWidth: "100%" }}
                      >
                        <label className="form-label">Status </label>
                        <select
                          name="stageTo"
                          value={stageTo}
                          className="form-control form-select select2"
                          onChange={(e) => {
                            setStageTo(e.target.value);
                          }}
                        >
                          <option>Select</option>
                          {statusMaster.map((data, index) => {
                            return (
                              <option value={data.name} key={index}>
                                {data.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-lg-12">
                        <button
                          className="btn ripple btn-primary"
                          type="button"
                          onClick={handleAssign3}
                        >
                          Click to Change
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`modal ${modelOPen4 ? "show" : ""}`}
              style={{ display: modelOPen4 ? "block" : "none" }}
              tabIndex="-1"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-xl"
                role="document"
              >
                <div className="modal-content rounded-3">
                  <div className="modal-header">
                    <h5 className="modal-title">Bulk PipeLine</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleCloseModal4}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body" style={{ width: "100%" }}>
                    <div className="row row-sm-1">
                      <div className="col-sm-4 form-group">
                        <label className="form-label">Assign : </label>
                        <select
                          name="leadPipelineTo"
                          value={leadPipelineTo}
                          className="form-control form-select select2"
                          onChange={(e) => {
                            setPipelineTo(e.target.value);
                          }}
                          // multiple={true}
                        >
                          <option>By Lead Pipeline</option>
                          {leadPipelineOptions.map((data, index) => {
                            return (
                              <option value={data.name} key={index}>
                                {data.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-lg-12">
                        <button
                          className="btn ripple btn-primary"
                          type="button"
                          onClick={handleAssign4}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`modal ${modelOPen5 ? "show " : ""} bg-black-7`}
              style={{
                display: modelOPen5 ? "block" : "none",
              }}
              tabIndex="-1"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-sm"
                role="document"
              >
                <div className="modal-content rounded-3">
                  <div className="modal-header">
                    <h5 className="modal-title">Bulk Assign Case</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleCloseModal5}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <div className="row row-sm-1">
                      <div className="mb-3">
                        <label className="form-label">Department</label>
                        <ReactSelect
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          // defaultValue={[
                          //   colourOptions[4],
                          //   colourOptions[5],
                          // ]}
                          onChange={(option) => {
                            handleDepartmentAssign1(option);
                          }}
                          styles={customStyles}
                          isMulti
                          options={departmentOptions}
                        />
                      </div>
                      <div
                        className="col-sm-4 form-group "
                        style={{ minWidth: "100%" }}
                        ref={dropdownRef}
                      >
                        <label className="form-label">Employee : </label>
                        <ReactSelect
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          onChange={(option) => {
                            handleCheckboxChangeAssign(option);
                          }}
                          styles={customStyles}
                          isMulti
                          options={employeeOptions}
                        />
                      </div>
                      <div className="col-lg-12">
                        <button
                          className="btn ripple btn-primary"
                          type="button"
                          onClick={(e) => handleAssign5(e)}
                        >
                          Assign
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cases;
