import React from "react";
import { useState } from "react";
import Loader from "../../assets/img/loader.gif";

const TabAPIComponent = ({ handleStatusChange1, linkActive, counts }) => {
  const [loader, setLoader] = useState(false);

  const handleLoader = () => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };
  return (
    <div className="tabs-menu">
      {/* Tabs */}
      <ul className="nav panel-tabs d-flex">
        <li>
          <a
            role="button"
            onClick={() => {
              handleStatusChange1("Pending on Tech");
              handleLoader();
            }}
            className={linkActive === "Pending on Tech" ? "active" : ""}
          >
            Pending on Tech ({counts.countCallAttempted})
          </a>
        </li>
        <li>
          <a
            role="button"
            onClick={() => {
              handleStatusChange1("Pending on First mile ops");
              handleLoader();
            }}
            className={
              linkActive === "Pending on First mile ops" ? "active" : ""
            }
          >
            Pending on First mile ops ({counts.countFollowup})
          </a>
        </li>
        <li>
          <a
            role="button"
            onClick={() => {
              handleStatusChange1("Pending on Last mile Ops");
              handleLoader();
            }}
            className={
              linkActive === "Pending on Last mile Ops" ? "active" : ""
            }
          >
            Pending on Last mile Ops ({counts.countLOST})
          </a>
        </li>
        <li>
          <a
            role="button"
            onClick={() => {
              handleStatusChange1("Pending on weight team");
              handleLoader();
            }}
            className={linkActive === "Pending on weight team" ? "active" : ""}
          >
            Pending on weight team ({counts.countconatactInFuture})
          </a>
        </li>
        <li>
          <a
            role="button"
            onClick={() => {
              handleStatusChange1("Pending on sales");
              handleLoader();
            }}
            className={linkActive === "Pending on sales" ? "active" : ""}
          >
            Pending on sales ({counts.countnegotiation})
          </a>
        </li>
        <li>
          <a
            role="button"
            onClick={() => {
              handleStatusChange1("Pending on legal");
              handleLoader();
            }}
            className={linkActive === "Pending on legal" ? "active" : ""}
          >
            Pending on legal ({counts.countproposal})
          </a>
        </li>
        <li>
          <a
            role="button"
            onClick={() => {
              handleStatusChange1("Pending on client");
              handleLoader();
            }}
            className={linkActive === "Pending on client" ? "active" : ""}
          >
            Pending on client ({counts.countOpen})
          </a>
        </li>
        <li>
          <a
            role="button"
            onClick={() => {
              handleStatusChange1("In Progress");
              handleLoader();
            }}
            className={linkActive === "In Progress" ? "active" : ""}
          >
            In Progress ({counts.totalCount})
          </a>
        </li>
      </ul>
      {loader ? (
        <>
          {" "}
          <div className="loading-overlay">
            <img src={Loader} alt="Loading..." />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TabAPIComponent;
